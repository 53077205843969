@import url("https://fonts.googleapis.com/css?family=Crete+Round|Dancing+Script&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@400;500&display=swap");
//@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');


html,
body {
	height: 100%;
	background-color: #e8e7e5;
	margin-top: 0px;
	padding-top: 0px;
	font-family: "Ubuntu", sans-serif;
}

/* Login css */

.header {
	text-align: center;
}

span {
	.dangerText {
		color: #dc3545;
	}

	.infoText {
		color: #17a2b8;
		font-weight: bold;
	}
}
.main {
	display: flex;
	flex-direction: column;
	// height: 100vh;
	min-height: 100%;
	background-image: url("../img/BG.png");
	background-repeat: no-repeat;
	background-color: rgb(128, 126, 126);
	left: 0px;
	top: 0px;
	background-size: 80% 60%;
	// z-index: -1;
	background-attachment: fixed;
	background-position: right center;
}

.bg-home {
	background-color: rgb(226, 238, 245);
}

.btn-border-radius {
	border-radius: 20px;
}

.login-container {
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	background-color: white;
	border-radius: 4px;
	margin-top: 20px;
	font-size: 1.2em;
	// width: 30% !important;
	align-items: stretch;
}

.header-ul {
	color: black !important;
}
.icon-up {
	// display: inline-block;
	border: dotted;
	text-align: center;
}
.new-size {
	font-size: 1em;
}

.text-body {
	text-align: center;
	margin-top: 14px;
	justify-content: center;
}

.icon-span {
	display: block;
	// padding-top: 2px;
}
.btn-span {
	display: block;
}

.icon-span-faq {
	display: block;
	font-weight: bold;
	//padding-top: 5px;
}

#ul-home li:focus,
#ul-home li:active {
	background-color: #1de054 !important;
}

.icon-plus {
	font-size: 1em;
	display: block;
}

.icon-plus-faq {
	font-size: 1em;
}

.bg-green {
	background-color: #1de054;
}

.text-green,
.form-profile label {
	color: #1de054;
}
.modal-title {
	flex-direction: column-reverse;
}

.white-text {
	color: white;
}

.login-btn {
	border-radius: 20px;
	background-color: white;
	color: black;
}
.modal-title h5 {
	align-self: center;
	text-transform: uppercase;
}
.modal-title .close {
	// color: ;
	background-color: #1de054;
	margin: 5px;
	border-radius: 0px;
	color: white;
	opacity: 1;
	padding: 6px;
}
.bg-white {
	background-color: white;
}

.home-container-div {
	height: 70%;
	width: 45%;
	margin-left: 80px;
	border-radius: 5px;
	justify-self: center;
	margin-top: 30px;
	padding-bottom: 30px;
	padding-top: 20px;
}

.home-bg-brown {
	background-color: rgb(90, 90, 92);
}

.home-nav-barm {
	list-style-type: none;
	padding: 5px;
	// margin-left: 300px;
	// margin-top: 10px;
	background-color: yellowgreen;
}

.transparent-input,
.form-login input {
	background-color: rgba(0, 0, 0, 0) !important;
	outline-color: #1de054 !important;
	box-shadow: transparent;
	border: #1de054;
	border-bottom: 2px solid white;
	color: white;
}

.transparent-input::-webkit-input-placeholder {
	color: rgb(36, 35, 35) !important;
}

.transparent-input:-moz-placeholder {
	/* Firefox 18- */
	color: rgb(36, 35, 35) !important;
}

.transparent-input:-ms-input-placeholder {
	color:  rgb(36, 35, 35) !important;
}

.form-login input:-moz-placeholder {
	color:  rgb(36, 35, 35) !important;
}

.form-login input::-webkit-input-placeholder {
	color:  rgb(36, 35, 35) !important;
}
.form-login input:-ms-input-placeholder {
	color:  rgb(36, 35, 35) !important;
}

.text-portal {
	color: white;
	font-size: 2em;
	line-height: normal;
	margin-top: 0px;
	font-family: "Dancing Script", cursive;
}

.text-plus {
	font-weight: bold;
	font-size: 1.6em;
	font-family: "Crete Round", serif;
	color: #1de054;
}

.text-number-green {
	font-weight: bold;
	font-size: 2em;
	font-family: "Open Sans", sans-serif;
	color: #1de054;
}

.text-number-white {
	font-weight: bold;
	font-size: 2em;
	font-family: "Open Sans", sans-serif;
	color: white;
}

.text-font-bold {
	font-weight: bolder;
}

.text-number-bolder {
	font-weight: bolder;
	font-size: 1.7rem;
}
.text-font-bold-green {
	font-size: 1em;
	font-weight: bolder;
	color: #1de054;
}

.text-font-bold-white {
	font-size: 1em;
	font-weight: bolder;
	color: white;
}

.supreme-container .fade {
	-webkit-filter: blur(1px);
	-moz-filter: blur(1px);
	-o-filter: blur(1px);
	-ms-filter: blur(1px);
	filter: blur(1px);
	opacity: 1;
}

.form-input input {
	outline: grey;
}

.white-bg-color {
	background-color: white;
}

#show-error {
	font-size: 1em;
	color: red;
	display: none;
}
#show {
	font-size: 1em;
	color: red;
	display: none;
}

.remove_shadow {
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	-o-box-shadow: none;
	box-shadow: none;
	border: 0px;
}

.centered-div {
	width: 100%;
	margin: 0 auto;
	padding: 20px;
}

.block {
	display: block;
}

#download-option {
	display: none;
}

.email-bg {
	background-color: rgb(244, 249, 250);
}

.remove-outline {
	background-color: #1de054;
	color: black;
}

.inactive {
	color: #b9b9b9;
}
.grey-bg {
	background-color: rgb(239, 241, 240) !important;
}

.foter-bottom-logo {
	// position: absolute;
	padding: 5px;
	bottom: 20;
}

.foter-bottom-logo-home {
	//  position: fixed;
	bottom: 0;
	right: 0;
	margin-top: 30px;
	margin-left: 250px;
	// background-color: honeydew;
}

.search {
	width: 100%;
	position: relative;
	display: flex;
}

.searchTerm {
	width: 100%;
	border-left: none;
	padding: 10px;
	height: 40px;
	border-radius: 0 0 0 0;
	outline: none;
	color: #9dbfaf;
}

// .searchTerm:focus{
//   color: white;
// }
.searchButtonFrom {
	width: 60px;
	height: 40px;
	background: #1de054;
	text-align: center;
	color: #fff;
	padding-top: 5px;
	padding-left: 3px;
	padding-right: 3px;
	border-radius: 5px 0 0 5px;
	cursor: pointer;
	font-size: 1em;
	font-weight: bolder;
}

.searchButtonTo {
	width: 60px;
	height: 40px;
	border: 1px solid white;
	background: #1de054;
	text-align: center;
	color: #fff;
	padding-top: 5px;
	border-radius: 5px 0 0 5px;
	cursor: pointer;
	font-size: 1em;
	font-weight: bolder;
}

/*Resize the wrap to see the search bar change!*/
.wrap {
	width: auto;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.paginationActive {
	color: white;
	background-color: gray;
}

.inline-div {
	display: inline-block;
	cursor: pointer;
}

.center-div {
	margin: auto;
	width: 50%;
	padding: 10px;
}

.warning-header {
	text-transform: none;
	font-size: small;
}

.smallest-font {
	font-size: 0.9rem;
}

#confirm-div {
	display: none;
}

.progress {
	width: 50px;
	height: 50px;
	background: none;
	position: relative;
}

.progress::after {
	content: "";
	width: 100%;
	height: 100%;
	border-radius: 50%;
	border: 6px solid #eee;
	position: absolute;
	top: 0;
	left: 0;
}

.progress > span {
	width: 50%;
	height: 100%;
	overflow: hidden;
	position: absolute;
	top: 0;
	z-index: 1;
}

.progress .progress-left {
	left: 0;
}

.progress .progress-bar {
	width: 100%;
	height: 100%;
	background: none;
	border-width: 6px;
	border-style: solid;
	position: absolute;
	top: 0;
}

.progress .progress-left .progress-bar {
	left: 100%;
	border-top-right-radius: 80px;
	border-bottom-right-radius: 80px;
	border-left: 0;
	transform-origin: center left;
}

.progress .progress-right {
	right: 0;
}

.progress .progress-right .progress-bar {
	left: -100%;
	border-top-left-radius: 80px;
	border-bottom-left-radius: 80px;
	border-right: 0;
	transform-origin: center right;
}

.progress .progress-value {
	position: absolute;
	top: 0;
	left: 0;
}

#upload-table {
	display: none;
}
#hide-progress {
	display: none;
}

.false {
	color: rgb(240, 93, 93);
}
.true {
	color: green;
}

#upload-form-msg {
	display: none;
}

.nav-item {
	display: flex;
	height: auto;
	text-align: center;
	justify-content: center;
}

.airtime-container {
	//position: absolute;
	top: 35px;
	bottom: 0;
	left: 0;
	right: 0;
	margin: 70px auto;
	height: 420px;
	width: 65%;
	background-color: whitesmoke;
	border-radius: 15px;
	padding: 0px;
}

.airtime-form {
	padding: 40px 30px;
	//height: 100%;
}

.data-form {
	//padding: 20px 30px;
	//height: 100%;
	padding-top: 50px;
	padding-left: 30px;
	padding-right: 20px;
	//width: 100%;
}

.airtime-form-input {
	margin-top: 20px;
}

.data-form-input {
	margin-top: 20px;
}

.img-container {
	width: 100%;
	position: relative;
	background-size: cover;
	background-position: center;
	height: 100%;
	border-top-right-radius: 15px;
	border-bottom-right-radius: 15px;
	padding: 0px;
}

.side-bar {
	background-color: black;
	color: white;
	//height: 420px;
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
	padding: 20px 0px;
	text-align: center;
}

.icon-style {
	font-size: 3.5em;
}

.side-bar-button {
	border-bottom: 1px solid white;
	height: 126px;
	padding-top: 20px;
}

.side-bar-button1 {
	border-bottom: 1px solid white;
	padding-top: 30px;
	height: 126px;
}

.side-bar-button2 {
	padding-top: 30px;
	height: 126px;
}

.btn-style {
	background: #1de054;
}

.main-menu {
	background: whitesmoke;
	border-radius: 5px;
	//margin-left: 200px;
	margin-top: 20px;
}

.netplus-logo {
	margin-top: 20px;
	float: left;
}

.side-bar-link {
	text-decoration: none;
}

.select-option {
	padding-right: 20px;
}

.errorMessage {
	color: red;
}

//v2

.nav-item-style {
	border-radius: 8px;
	background-color: #fff;
	color: black;
	height: 9em;
	width: 11em;
	text-align: center;
	padding: 10px 5px;
	display: inline-block;
	font-family: "Ubuntu", sans-serif;
	font-weight: 400;
	margin: 0px 13px;
}

.show-msg {
	color: red;
	display: block !important;
	margin-bottom: 10px;
}

.show-msg-success {
	color: green !important;
	display: block !important;
	margin-bottom: 10px;
}

.right-button {
	float: right;
	margin-right: -100px;
}

.menu-toggle {
	display : none;
}

.nav-container {
	margin-left: 9em;
}

.nav-link:active {
	background-color: #1de054 !important;
}

.nav-item-style:hover {
	background-color: #1de054 !important;
}

// .btn:active {
// 	background: #1de054 !important;
	
// }
// .btn:focus {
// 	background: #1de054 !important;
// }
// .btn:clicked {
// 	background: #1de054 !important;
// }

.bill-btn {
	background: #1de054 !important;
	float: right;
	color: white !important;
}

.bill-btn :clicked {
	background: #1de054 !important;
	color: white;
}

.bill-btn :active {
	background: #1de054 !important;
	color: white;
}

.bill-btn :focus {
	background: #1de054 !important;
	color: white;
}

#topupAmount, #meterNumb, #service_charge, #exampleFirst, #exampleLast, 
#exampleEmail, #examplePhone, #exampleFrom, #exampleTo{
	background-color: white !important;
	// color: black !important;
}



#topupAmount:-webkit-autofill:focus,
#topupAmount:-webkit-box-shadow {
	background-color: white !important;
}
#topupAmount:-webkit-autofill,
#topupAmount:-webkit-autofill:hover,
#topupAmount:-webkit-autofill:focus {
	-webkit-text-fill-color: black;
	// -webkit-box-shadow: 0 0 0px 1000px  inset;
}

//  .nav-item-style:active:focus{
//   background-color: #1DE054 !important;
// }

.net-logo {
	width: 11em;
	margin-bottom: 0px;
	margin-top: 10px;
	margin-left: 11em;
}

.landing-page-img {
	width: 30em;
	margin-left: 13em;
	margin-top: 90px;
}

.form-container {
	background-color: white;
	padding: 20px 40px;
	width: 27em;
	margin-left: 110px;
	margin-top: 20px;
	height: 100%;
	//margin-right: -30em;
}

// .landing-form-input {
// 	margin-top: 20px;
// 	color: black;
// 	background-color: #1de054 !important;
// }

.landing-form-input {
	margin-top: 20px;
	color: white;
	
}

.landing-form-input1 {
	margin-top: 10px;
	color: white;
	
}

// input:-webkit-autofill,
// input:-webkit-autofill:hover,
// input:-webkit-autofill:focus {
//   -webkit-text-fill-color: black;
//   //-webkit-box-shadow: 0 0 0px 1000px #1DE054 inset;
//   //background-color: #1de054 !important;
// }

.landing-form-control .form-control {
	background-color: #1de054 !important;
	// color: white !important;
	font-family: "Ubuntu", sans-serif;
	font-weight: 500;
}



.landing-form-control {
	background-color: #1de054 !important;
	color: white !important;
	font-family: "Ubuntu", sans-serif;
	font-weight: 500;
}

.landing-form-control :focus {
	background-color: #1de054 !important;
	color: white !important;
}

.landing-form-control :blur {
	background-color: #1de054 !important;
	color: white !important;
}

.landing-form-control :active {
	background-color: #1de054 !important;
	color: white !important;
}

.landing-form-input ::placeholder {
	color: white;
	font-size: 12px;
	padding-left: 5px;
	font-family: "Ubuntu", sans-serif;
	font-weight: 500;
	text-transform: none !important;
	
}

.landing-form-input1 ::placeholder {
	color: white;
	font-size: 12px;
	padding-left: 5px;
	font-family: "Ubuntu", sans-serif;
	font-weight: 500;
	text-transform: none !important;
	
}

input[type="email"] {
	text-transform: none;
	text-transform: white;
}

.show-msg1 {
	display: contents;
}

.landing-form-input select :focus,
.landing-form-input select :active {
	color: white;
}

.landing-form-input select {
	color: white;
	font-size: 12px;
	//padding-left: 8px;
	font-family: "Ubuntu", sans-serif;
	font-weight: 500;
}

.show-msg1 {
	display: contents;
}

.landing-form-input select :focus,
.landing-form-input select :active {
	color: white;
}

.menu-icon {
	font-size: 3em;
	display: inline-block;
}

.fa-icon {
	margin-left: 60px;
	text-align: center;
	display: block;
}

.fa-icon1 {
	margin-left: 45px;
	text-align: center;
	display: block;
}

.fa-icon-pay {
	margin-left: 47px;
	text-align: center;
	display: block;
}

.menu-option {
	margin-top: 10px !important;
	font-size: 13px !important;
	font-family: "Ubuntu", sans-serif;
	font-weight: 700 !important;
}

.menu-tag {
	font-size: 11px !important;
	font-family: "Ubuntu", sans-serif;
}

.landing-buttons {
	background-color: #1de054;
	margin-top: 0px;
	float: right;
	font-family: "Ubuntu", sans-serif;
}

.airtime-button {
	font-family: "Ubuntu", sans-serif;
}

.landing-buttons-container {
	margin-right: -4em;
}

.landing-footer {
	height: 20px;
	margin-top: -50px;
	margin-left: 15em;
	margin-right: 80px;
}

.content-inside {
	padding-bottom: 90px;
}

.footer-item {
	margin-right: 10px;
	font-size: 11px;
}

.call-center {
	width: 90px;
	float: right;
	margin-left: 30px;
	margin-right: -9em;
	margin-top: -50px;
	border-radius: 50%;
	padding: 10px;
	background-color: #1de054;
	// margin-bottom: 20px;
}

.buy-power-img {
	width: 22em;
	margin-left: 16em;
	margin-top: 25px;
	margin-bottom: 50px;
}

.form-header {
	font-family: "Ubuntu", sans-serif;
	font-weight: 700 !important;
	font-size: 13px;
}
.bills-page-img {
	width: 27em;
	margin-left: 14em;
	margin-top: 30px;
	//margin-bottom: 40px;
}

.power-page-img {
	width: 28em;
	margin-left: 15em;
	//margin-top: 30px;
	//margin-bottom: 40px;
}

.internet-page-img {
	width: 28em;
	margin-left: 15em;
	margin-top: 70px;
	//margin-bottom: 40px;
}

.forgot-text {
	font-family: "Ubuntu", sans-serif;
	font-weight: 700 !important;
	font-size: 10px;
	float: right;
}

.forgot-text1 {
	font-family: "Ubuntu", sans-serif;
	font-weight: 700 !important;
	font-size: 10px;
	float: right;
	padding-bottom: 5em;
}

.reset-text {
	color: #1de054;
}

.reset-text:hover {
	color: #1de054;
	text-decoration: none;
}

.form-container-estates {
	padding-bottom: 5em;
}

.bills-form-container {
	padding-bottom: 15em;
}

.landingpage-woman {
	width: 60em;
	margin-left: 0px;
	margin-bottom: 80px;
}

.welcome-text {
	font-weight: 700;
	font-size: 2em;
	line-height: 1em;
}

.welcome-text-container {
	margin-top: 4em;
	margin-left: 40px;
	padding: 10px;
}

.landing-form {
	margin-bottom: 5em;
}

.landing-fee {
	float: right;
	color: red;
	font-size: 12px;
	font-family: "Ubuntu", sans-serif;
	font-weight: 700 !important;
	margin-bottom: 10px;
}

.landing-container {
	max-height: 100%;
	max-width: 100%;
}

.landing-child-container {
	max-height: 90%;
	max-width: 90%;
}

.summary-logo {
	width: 9em;
	float: right;
	margin-top: 15px;
}

.summary-container {
	padding-bottom: 37px;
	text-align: center;
	font-size: 15px;
}

.summary-success {
	width: 12em;
}

.summary-failure {
	width: 10em;
	margin-bottom: 12px;
}

.summary-header {
	font-size: 18px;
	font-family: "Ubuntu", sans-serif;
	font-weight: 700 !important;
}

.summary-button {
	background-color: #1de054;
}

.summary-content {
	line-height: 8px;
	padding-top: 15px !important;
}

.summary-content1 {
	line-height: 8px;
	padding-top: 24px !important;
}

.token{
	font-size: 15px;
	font-family: "Ubuntu", sans-serif;
	font-weight: 700 !important;
	margin: 20px 0px;
}

.confirm-header{
	font-weight: 700;
	font-family: 'Montserrat', sans-serif;
	font-size: 14px;
	
}

.confirm {
	font-weight: 700;
	font-family: 'Montserrat', sans-serif;
}

.confirm-div{
	font-size: 12px;
	/* text-align: center; */
	margin-top: 30px;
	margin-bottom: 50px;
	text-transform: capitalize;
}

.confirm-btn1{
	float: left
}

.confirm-btn2{
	float: right
}

.switch {
	position: relative;
	display: inline-block;
	width: 50px;
	height: 24px;
	/* background-color: red; */
}

.switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #c4c4c4;
	transition: 0.4s;
	border-radius: 50px;

}

.slider::before {
	position: absolute;
	content: "";
	height: 18px;
	width: 23px;
	bottom: 3px;
	background-color: white;
	transition: 0.4s;
	border-radius: 50%;
}

input:checked + .slider {
	background-color: #1de054;
}

input:checked + .slider::before {
	transform: translate(23px);
}


@media only screen and (max-device-width: 329px) {
	.home-container-div {
		width: 80%;
		height: 73%;
		margin-top: 0px;
	}

	.netplus-logo {
		width: 70%;
		height: 70%;
	}

	.netplus-svg {
		margin-left: 4px;
	}

	.foter-bottom-logo-home {
		margin-top: 10px;
	}

	.buy-button {
		width: 90px;
		text-align: center;
		font-size: 16px;
		padding: 7px 7px;
	}

	.text-plus {
		font-size: 23px;
	}

	.lead {
		font-size: 20px;
	}

	.side-bar {
		border-top-right-radius: 15px;
		border-bottom-left-radius: 0px;
		padding: 15px 0px;
		width: 89%;
		margin-left: 15px;
	}

	.side-bar-button {
		padding-top: 20px;
	}

	.side-bar-button1 {
		padding-top: 30px;
	}

	.side-bar-button2 {
		padding-top: 30px;
	}

	.icon-style {
		font-size: 1.5em;
	}

	.airtime-container {
		margin: 40px auto;
		width: 75%;
		height: 760px;
	}

	.data-form {
		padding-top: 20px;
		padding-left: 20px;
		padding-right: 20px;
	}

	.left-col {
		display: none;
	}

	.net-logo {
		//float: right;
		width: 9em;
		margin-left: 9em !important;
		margin-top: 30px;
	}

	.nav-container {
		margin-left: 0em;
	}

	.form-container {
		margin-top: 7em;
		padding: 20px 20px;
		width: 16em;
		margin-left: -17em;
		height: auto;
		//margin-right: -30em;
	}

	.landing-form-input {
		margin-top: 10px;
		color: white;
	}

	.landing-form-input1 {
		margin-top: 10px;
		color: white;
	}

	.landing-footer {
		height: 20px;
		margin-top: 20px;
		margin-left: 10px;
		margin-right: 0px;
		margin-bottom: 10px;
	}

	.content-inside {
		padding-bottom: 50px;
	}

	.footer-item {
		//padding: 0px;
		margin-right: 0px;
		font-size: 8px;
		//line-height: 1px;
	}

	.call-center {
		width: 50px;
		margin-right: -2em;
		margin-top: -10px;
	}

	.form-header {
		font-size: 12px;
	}

	.landing-form-input ::placeholder {
		font-size: 10px;
		padding-left: 2px;
	}

	.forgot-text {
		font-size: 10px !important;
	}

	.reset-text{
		color: #1de054 !important;
	}

	.airtime-button {
		font-size: 10px;
	}

	.landing-form {
		margin-bottom: 3em;
	}

	.nav-item-style {
		// float: left;
		border-radius: 8px;
		background-color: #fff;
		color: black;
		height: 6.5em;
		width: 19em;
		text-align: center;
		padding: 10px 80px;
		display: block;
		margin-bottom: 0px;
		margin-left: 2em;
	}

	.fa-icon {
		margin-left: 1.5em;
		text-align: center;
		display: block;
		font-size: 2em;
	}

	.fa-icon1 {
		margin-left: 1em;
		text-align: center;
		display: block;
		font-size: 2.5em;
	}

	.menu-option {
		margin-top: 10px !important;
		font-size: 12px !important;
		text-align: center;
		//margin-right: 30px;
	}

	.menu-tag {
		font-size: 9px !important;
		float: bottom;
	}

	.navbar-toggler-icon {
		margin-top: 20px;
	}
	.navbar-collapse ul {
		//width: 200px;
		margin-top: 20px;
		//height: 50%;
		//display: table !important;
	}

	.toggle-container {
		display : none;
	}

	.menu-toggle {
		display: contents;
	}

	.landing-form-control{
		margin-top: 10px;
	}

	.landing-form-input select {
		
		font-size: 10px;
	}

	.confirm-header{
		font-weight: 700 !important;
		font-family: 'Montserrat', sans-serif;
		font-size: 12px;
		display: block !important;
	}
	
	.confirm {
		font-weight: 700;
		font-family: 'Montserrat', sans-serif;
		// display: block !important;
	}
	
	.confirm-div{
		font-size: 12px;
		 text-align: left; 
		margin-top: 20px;
		margin-bottom: 40px;
		text-transform: capitalize;
		// padding: 0px 20px;
		display: block !important;
	}
	
	.confirm-btn1{
		display: block;
		float: none;
		font-size: 10px !important;
		margin-top: 40px;
	}
	
	.confirm-btn2{
		float: right;
		font-size: 10px;
		margin-top: -45px;
	}
	
}

@media only screen and (min-device-width: 330px) and (max-device-width: 360px) {
	.home-container-div {
		width: 80%;
		height: 75%;
		margin-top: 0px;
		padding-bottom: 20px;
	}

	.netplus-logo {
		width: 80%;
		height: 80%;
		//margin-left: 20px;
	}

	.foter-bottom-logo-home {
		margin-top: 40px;
	}

	.buy-button {
		width: 90px;
		text-align: center;
		font-size: 16px;
		padding: 10px 10px;
	}

	.side-bar {
		border-top-right-radius: 15px;
		border-bottom-left-radius: 0px;
		padding: 15px 0px;
		width: 90%;
		margin-left: 15px;
	}

	.side-bar-button {
		padding-top: 20px;
	}

	.side-bar-button1 {
		padding-top: 30px;
	}

	.side-bar-button2 {
		padding-top: 30px;
	}

	.icon-style {
		font-size: 2em;
	}

	.airtime-container {
		margin: 40px auto;
		width: 75%;
		height: 760px;
	}

	.data-form {
		padding-top: 20px;
		padding-left: 20px;
		padding-right: 20px;
	}

	.left-col {
		display: none;
	}

	.net-logo {
		float: right;
		width: 10em;
		margin-left: 11em;
		margin-top: 20px;
	}

	.nav-container {
		margin-left: 0em;
	}

	.form-container {
		margin-top: 7em;
		padding: 20px 20px;
		width: 18.5em;
		margin-left: -19.5em;
		height: auto;
	}

	.fa-icon-pay {
		margin-left: 1.2em;
	}

	.landing-form-input {
		margin-top: 10px;
		color: white;
	}

	.landing-form-control{
		margin-top: 10px;
	}

	.landing-form-input1 {
		margin-top: 10px;
		color: white;
	}

	.landing-footer {
		margin-top: 5em;
		margin-left: 0px;
		margin-right: 0px;
		margin-bottom: 0px;
	}

	.content-inside {
		padding-bottom: 50px;
	}

	.footer-item {
		margin-right: 0px;
		font-size: 10px;
		line-height: 15px;
	}

	.call-center {
		width: 50px;
		float: right;
		margin-right: -1em;
		margin-top: -10px;
	}

	.form-header {
		font-size: 12px !important;
	}

	.landing-form-input ::placeholder {
		font-size: 10px;
		padding-left: 2px;
	}

	.forgot-text {
		font-size: 8px !important;
	}

	.reset-text{
		color: #1de054 !important;
	}

	.airtime-button {
		font-size: 10px;
	}

	.landing-form {
		margin-bottom: 3em;
	}

	.nav-item-style {
		// float: left;
		border-radius: 8px;
		background-color: #fff;
		color: black;
		height: 7em;
		width: 22em;
		text-align: center;
		padding: 10px 90px;
		display: block;
		margin-bottom: 0px;
		margin-left: 30px;
	}

	.fa-icon {
		margin-left: 1.5em;
		text-align: center;
		display: block;
		font-size: 2.5em;
	}

	.fa-icon1 {
		margin-left: 1.3em;
		//text-align: center;
		display: block;
		font-size: 2.5em;
	}

	.menu-option {
		margin-top: 10px !important;
		font-size: 14px !important;
		//text-align: center;
		//margin-right: 30px;
	}

	.menu-tag {
		font-size: 9px !important;
		//float: bottom;
	}

	.navbar-toggler-icon {
		margin-top: 20px;
	}
	.navbar-collapse ul {
		//width: 200px;
		margin-top: 20px;
		//height: 50%;
		//display: table !important;
	}

	.toggle-container {
		display : none;
	}

	.menu-toggle {
		display: contents;
	}

	.landing-form-input select {
		
		font-size: 10px;
	}

	.confirm-header{
		font-weight: 700 !important;
		font-family: 'Montserrat', sans-serif;
		font-size: 12px;
		display: block !important;
	}
	
	.confirm {
		font-weight: 700;
		font-family: 'Montserrat', sans-serif;
		// display: block !important;
	}
	
	.confirm-div{
		font-size: 14px;
		 text-align: left; 
		margin-top: 20px;
		margin-bottom: 40px;
		text-transform: capitalize;
		// padding: 0px 20px;
		display: block !important;
	}
	
	.confirm-btn1{
		display: block;
		float: none;
		font-size: 10px !important;
		margin-top: 40px;
	}
	
	.confirm-btn2{
		float: right;
		font-size: 10px;
		margin-top: -45px;
	}
	
}

@media only screen and (min-device-width: 361px) and (max-device-width: 460px) {
	.home-container-div {
		width: 80%;
		height: 70%;
		margin-top: 10px;
	}

	.netplus-logo {
		width: 90%;
		height: 90%;
	}

	.text-plus {
		font-size: 35px;
	}

	.home-form {
		margin-top: 20px;
	}

	.buy-button {
		width: 90px;
		text-align: center;
		font-size: 20px;
		padding: 10px 10px;
	}

	.lead {
		font-size: 30px;
	}

	.side-bar {
		border-top-right-radius: 15px;
		border-bottom-left-radius: 0px;
		padding: 15px 0px;
		width: 90.8%;
		margin-left: 15px;
	}

	.side-bar-button {
		padding-top: 20px;
	}

	.side-bar-button1 {
		padding-top: 30px;
	}

	.side-bar-button2 {
		padding-top: 30px;
	}

	.icon-style {
		font-size: 2.5em;
	}

	.airtime-container {
		margin: 40px auto;
		width: 75%;
		height: 760px;
	}

	.data-form {
		padding-top: 20px;
		padding-left: 20px;
		padding-right: 20px;
	}

	.left-col {
		display: none;
	}

	.net-logo {
		float: right;
		width: 10em;
		margin-left: 13em;
		margin-top: 30px;
  }
  
  .nav-container{
    margin-left: 0em;
  }

	.form-container {
		margin-top: 8em;
		padding: 20px 20px;
		width: 21em;
		margin-left: -22em;
		margin-bottom: 1em;
		height: auto;
	}

	.landing-form-input {
		margin-top: 15px;
	}

	.landing-footer {
		height: 20px;
		margin-top: 8em;
		margin-left: 20px;
		margin-right: 40px;
		margin-bottom: 10px;
	}

	.content-inside {
		padding-bottom: 50px;
	}

	.footer-item {
		margin-right: 0px;
		font-size: 8px;
		line-height: 15px;
	}

	.call-center {
		width: 50px;
		float: right;
		margin-right: -4em;
		margin-top: -10px;
		
	}

	.form-header {
		font-size: 14px;
	}

	.landing-form-input ::placeholder {
		font-size: 12px;
		padding-left: 3px;
	}

	.forgot-text {
		font-size: 10px !important;
	}

	.reset-text {
		color: #1de054 !important;
	}

	.airtime-button {
		font-size: 12px;
	}

	.landing-form {
		margin-bottom: 5em;
	}
.landing-form-control {
	margin-top: 10px;
}
	

	.nav-item-style {
		// float: left;
		border-radius: 8px;
		background-color: #fff;
		color: black;
		height: 8em;
		width: 25em;
		text-align: center;
		padding: 10px 90px;
		display: block;
		margin-bottom: 0px;
		margin-left: 30px;
	}

	.fa-icon {
		margin-left: 1.5em;
		text-align: center;
		display: block;
		font-size: 3em;
	}

	.fa-icon1 {
		margin-left: 1.5em;
		text-align: center;
		display: block;
		font-size: 3em;
	}

	.fa-icon-pay {
		margin-left: 1.5em;
	}

	.menu-option {
		margin-top: 10px !important;
		font-size: 14px !important;
		text-align: center;
		//margin-right: 30px;
	}

	.menu-tag {
		font-size: 11px !important;
		float: bottom;
	}

	.navbar-toggler-icon {
		margin-top: 20px;
	}
	.navbar-collapse ul {
		//width: 200px;
		margin-top: 20px;
		//height: 50%;
		//display: table !important;
	}

	.toggle-container {
		display : none;
	}

	.menu-toggle {
		display: contents;
	}

	.show-msg{
		display: block !important;
	}

	.landing-form-input select {
		
		font-size: 10px;
	}

	.confirm-header{
		font-weight: 700 !important;
		font-family: 'Montserrat', sans-serif;
		font-size: 12px;
		display: block !important;
	}
	
	.confirm {
		font-weight: 700;
		font-family: 'Montserrat', sans-serif;
		// display: block !important;
	}
	
	.confirm-div{
		font-size: 16px;
		 text-align: left; 
		margin-top: 20px;
		margin-bottom: 40px;
		text-transform: capitalize;
		// padding: 0px 20px;
		display: block !important;
	}
	
	.confirm-btn1{
		display: block;
		float: none;
		font-size: 10px !important;
		margin-top: 40px;
	}
	
	.confirm-btn2{
		float: right;
		font-size: 10px;
		margin-top: -45px;
	}
	
}

@media (width: 375px) {
	.data-form {
		padding-top: 20px;
		padding-left: 20px;
		padding-right: 20px;
	}

	.left-col {
		display: none;
	}

	.net-logo {
		float: right;
		width: 10em;
		margin-left: 12em;
		margin-top: 30px;
  }
  
  .nav-container {
    margin-left: 0em;
  }


	.form-container {
		margin-top: 9em;
		padding: 20px 20px;
		width: 20em;
		margin-left: -21em;
		height: auto;
	}

	.landing-form-control{
		margin-top: 10px;
	}

	.landing-form-control ::placeholder{
		margin-top: 5px;
		font-size: 10px;
	}

	.landing-form-input {
		margin-top: 20px;
	}

	.reset-text {
		color: #1de054 !important;
	}


	.landing-footer {
		height: 20px;
		margin-top: 13em;
		margin-left: 20px;
		margin-right: 0px;
		margin-bottom: 0px;
	}

	.content-inside {
		padding-bottom: 50px;
	}

	.footer-item {
		margin-right: 0px;
		font-size: 8px;
		line-height: 15px;
	}

	.call-center {
		width: 50px;
		float: right;
		margin-right: 0em;
		margin-top: -10px;
	}

	.form-header {
		font-size: 14px;
	}

	.landing-form-input ::placeholder {
		font-size: 10px;
		padding-left: 3px;
	}

	.landing-form-input select {
		
		font-size: 10px;
	}

	.forgot-text {
		font-size: 10px !important;
		
	}

	.airtime-button {
		font-size: 12px;
	}

	.landing-form {
		margin-bottom: 5em;
	}

	.menu-toggle {
		display: contents;
	}

	.nav-item-style {
		
		margin-left: 30px;
		border-radius: 8px;
		background-color: #fff;
		color: black;
		height: 7.5em;
		width: 23em;
		text-align: center;
		padding: 10px 90px;
		display: block;
		margin-bottom: 0px;
	}

	.fa-icon {
		margin-left: 1.5em;
		text-align: center;
		display: block;
		font-size: 2.5em;
	}

	.fa-icon1 {
		margin-left: 1.4em;
		text-align: center;
		display: block;
		font-size: 2.5em;
	}

	.menu-option {
		margin-top: 10px !important;
		font-size: 12px !important;
		text-align: center;
		//margin-right: 30px;
	}

	.menu-tag {
		font-size: 9px !important;
		float: bottom;
	}

	.navbar-toggler-icon {
		margin-top: 20px;
	}
	.navbar-collapse ul {
		//width: 200px;
		margin-top: 20px;
		//height: 50%;
		//display: table !important;
	}
	.toggle-container {
		display : none;
	}

	.confirm-header{
		font-weight: 700 !important;
		font-family: 'Montserrat', sans-serif;
		font-size: 12px;
		display: block !important;
	}
	
	.confirm {
		font-weight: 700;
		font-family: 'Montserrat', sans-serif;
		// display: block !important;
	}
	
	.confirm-div{
		font-size: 16px;
		 text-align: left; 
		margin-top: 20px;
		margin-bottom: 40px;
		text-transform: capitalize;
		// padding: 0px 20px;
		display: block !important;
	}
	
	.confirm-btn1{
		display: block;
		float: none;
		font-size: 10px !important;
		margin-top: 40px;
	}
	
	.confirm-btn2{
		float: right;
		font-size: 10px;
		margin-top: -45px;
	}
	
}


@media all and (min-width: 2000px) and (max-width: 2560px) {
	.nav-item-style {
		border-radius: 8px;
		background-color: #fff;
		color: black;
		height: 17em;
		width: 21em;
		text-align: center;
		padding: 10px 35px;
		display: inline-block;
		
		
		margin: 0px 15px;
	}

	.net-logo {
		width: 20em;
		margin-bottom: 10px;
		margin-top: 30px;
		margin-left: 10em;
	}

	.landing-page-img {
		width: 40em;
		margin-left: 25em;
		margin-top: 85px;
	}

	.form-container {
		margin-top: 50px;
		width: 35em;
		margin-left: 10em;
		margin-right: -30em;
		
	}

	.landing-form-input ::placeholder {
		font-size: 25px;
		padding: 7px;
	}

	.landing-form-input1 ::placeholder {
		color: white;
		font-size: 25px;
		padding: 7px;
		font-family: "Ubuntu", sans-serif;
		font-weight: 500;
	}

	.landing-form-input select {
		color: white;
		font-size: 25px;
		padding: 7px;
	}

	.menu-icon {
		//font-size: 18em !important;
		display: inline-block;
	}

	.fa-icon {
		margin-left: 80px;
		text-align: center;
		display: block;
		font-size: 5em;
	}

	.fa-icon1 {
		margin-left: 60px;
		text-align: center;
		display: block;
		font-size: 5em;
	}

	.menu-option {
		margin-top: 10px !important;
		font-size: 25px !important;
		
	}

	.menu-tag {
		font-size: 19px !important;
		font-family: "Ubuntu", sans-serif;
	}

	.landing-footer {
		height: 20px;
		margin-top: 30em;
		margin-left: 15em;
		margin-right: 80px;
	}

	.content-inside {
		padding-bottom: 30px;
	}

	.footer-item {
		margin-right: 10px;
		font-size: 11px;
	}

	.call-center {
		width: 90px;
		float: right;
		//margin-right: 0em;
		margin-top: -50px;
		border-radius: 50%;
		padding: 10px;
		background-color: #1de054;
		// margin-bottom: 20px;
	}

	.buy-power-img {
		width: 28em !important;
		margin-left: 30em;
		margin-top: 20em;
		margin-bottom: 50px;
	}

	.form-header {
		font-family: "Ubuntu", sans-serif;
		font-weight: 700 !important;
		font-size: 23px;
	}
	.bills-page-img {
		width: 37em;
		margin-left: 20em;
		//margin-top: 30px;
		//margin-bottom: 40px;
	}

	.power-page-img {
		width: 37em;
		margin-left: 20em;
		margin-top: 20px;
		//margin-bottom: 40px;
	}

	.internet-page-img {
		width: 40em;
		margin-left: 15em;
		margin-top: 50px;
		//margin-bottom: 40px;
	}

	.forgot-text {
		font-family: "Ubuntu", sans-serif;
		font-weight: 700 !important;
		font-size: 15px;
		float: right;
	}

	.forgot-text1 {
		font-family: "Ubuntu", sans-serif;
		font-weight: 700 !important;
		font-size: 15px;
		float: right;
		padding-bottom: 5em;
	}

	.reset-text {
		color: #1de054;
	}

	.reset-text:hover {
		color: #1de054;
		text-decoration: none;
	}

	.form-container-estates {
		padding-bottom: 5em;
	}

	.bills-form-container {
		padding-bottom: 15em;
	}

	.landingpage-woman {
		width: 60em;
		margin-left: 0px;
		margin-bottom: 80px;
	}

	.welcome-text {
		font-weight: 700;
		font-size: 2em;
		line-height: 1em;
	}

	.welcome-text-container {
		margin-top: 4em;
		margin-left: 40px;
		padding: 10px;
	}

	.landing-form {
		margin-bottom: 5em;
	}

	.landing-fee {
		float: right;
		color: red;
		font-size: 12px;
		font-family: "Ubuntu", sans-serif;
		font-weight: 700 !important;
		margin-bottom: 10px;
	}

	.landing-container {
		min-height: 100%;
	}
}
@media all and (min-width: 995px) and (max-width: 1024px) {
	.home-container-div {
		width: 80%;
		height: 100%;
		margin-left: 5px;
		margin-top: 70px;
		margin-bottom: 30px;
		align-self: center;
	}

	.text-plus {
		font-size: 55px;
	}

	.home-form {
		margin-top: 30px;
		margin-bottom: 50px;
	}

	.buy-button {
		text-align: center;
		font-size: 35px;
	}

	.lead {
		font-size: 45px;
	}

	.text-portal {
		font-size: 50px;
	}

	.side-bar {
		border-top-left-radius: 15px;
		border-bottom-left-radius: 15px;
		padding: 15px 0px;
	}

	.side-bar-button {
		padding-top: 20px;
	}

	.side-bar-button1 {
		padding-top: 30px;
	}

	.side-bar-button2 {
		padding-top: 30px;
	}

	.icon-style {
		font-size: 4em;
	}

	.airtime-container {
		margin: 90px auto;
		width: 85%;
		height: 410px;
	}

	.data-form {
		padding-top: 20px;
		padding-left: 10px;
		padding-right: 0px;
	}

	.net-logo {
		margin-top: 50px;
	}
	.buy-power-img {
		margin-left: 15em;
		margin-top: 60px;
	}

	.landing-page-img {
		margin-left: 15em;
		margin-top: 150px;
	}

	.internet-page-img {
		margin-left: 10em;
		margin-top: 90px;
	}

	.bills-page-img {
		// margin-left: 80px;
		margin-top: 60px;
		margin-left: 15em;
	}

	.power-page-img {
		margin-left: 15em;
		margin-top: 80px;
	}

	.form-container {
		margin-top: 60px;
		padding: 20px 20px;

		margin-left: 18em;
		margin-right: -30em;
	}

	.nav-item-style {
		margin-top: 20px;
	}

	.landing-footer {
		height: 20px;
		margin-top: 50em;
		margin-left: 17em;
		margin-right: 0px;
		margin-bottom: -50px;
	}

	.content-inside {
		padding-bottom: 50px;
	}

	.footer-item {
		//padding: 0px;
		margin-right: 0px;
		font-size: 15px;
		//line-height: 1px;
	}

	.call-center {
		width: 50px;
		margin-right: -10em;
		margin-top: -10px;
	}

}

@media (width: 768px) {
	.home-container-div {
		width: 80%;
		margin-left: 5px;
		margin-top: 70px;
		align-self: center;
	}
	.center-btn-text {
		text-align: center;
		display: inline-block;
		vertical-align: middle;
	}

	.side-bar {
		border-top-left-radius: 15px;
		//border-top-right-radius: 15px;
		border-bottom-left-radius: 15px;
		padding: 15px 0px;
		width: 95%;
		margin-left: 15px;
	}

	.icon-style {
		font-size: 3em;
	}

	.airtime-container {
		margin: 90px auto;
		width: 90%;
		height: 410px;
	}

	.data-form {
		padding-top: 20px;
		padding-left: 20px;
		padding-right: 20px;
	}

	.left-col {
		display: none;
	}

  .nav-container{
    margin-left: 1em;
  }

	.net-logo {
		width: 13em;
		float: right;
		margin-bottom: 20px;
		margin-top: 40px;
		margin-left: 27em;
	}

	.form-container {
		background-color: white;
		padding: 30px 40px;
		width: 36em;
		margin-top: 10em;
		margin-left: -37em;
		margin-bottom: 20px;
		height: auto;
		//margin-right: -30em;
	}

	.landing-form-control {
		margin-top: 15px;
	}


	.landing-footer {
		height: 20px;
		margin-top: 25em;
		margin-left: 30px;
		margin-right: 0px;
		margin-bottom: 0px;
	}

	.content-inside {
		padding-bottom: 40px;
	}

	.footer-item {
		margin-right: 0px;
		font-size: 12px;
		line-height: 15px;
	}

	.call-center {
		width: 70px;
		float: right;
		margin-right: 0em;
		margin-top: -40px;
	}

	.form-header {
		font-size: 18px;
	}

	.landing-form-input ::placeholder {
		font-size: 15px;
		padding-left: 3px;
	}

	.forgot-text {
		font-size: 12px;
	}

	.reset-text {
		color: #1de054 !important;
	}

	.airtime-button {
		font-size: 15px;
	}

	// .landing-form {
	//   margin-bottom: 5em;
	// }

	.nav-item-style {
		// float: left;
		border-radius: 8px;
		background-color: #fff;
		color: black;
		height: 9em;
		width: 42em;
		text-align: center;
		padding: 10px 200px;
		display: block;
		margin-bottom: 0px;
		margin-left: 70px;
	}

	.fa-icon {
		margin-left: 1.5em;
		text-align: center;
		display: block;
		font-size: 3.5em;
	}

	.fa-icon1 {
		margin-left: 1.2em;
		text-align: center;
		display: block;
		font-size: 3.5em;
	}

	.fa-icon-pay {
		margin-left: 1.2em;
	}

	.menu-option {
		margin-top: 10px !important;
		font-size: 15px !important;
		text-align: center;
		//margin-right: 30px;
	}

	.menu-tag {
		font-size: 12px !important;
		float: bottom;
	}

	.navbar-toggler-icon {
		margin-top: 40px;
	}
	.navbar-collapse ul {
		margin-top: 60px;
	}

	.toggle-container {
		display : none;
	}

	.menu-toggle {
		display: contents;
	}

	.confirm-header{
		font-weight: 700 !important;
		font-family: 'Montserrat', sans-serif;
		font-size: 12px;
		display: block !important;
	}
	
	.confirm {
		font-weight: 700;
		font-family: 'Montserrat', sans-serif;
		// display: block !important;
	}
	
	.confirm-div{
		font-size: 22px;
		 text-align: left; 
		margin-top: 20px;
		margin-bottom: 40px;
		text-transform: capitalize;
		// padding: 0px 20px;
		display: block !important;
	}
	
	.confirm-btn1{
		display: block;
		float: none;
		font-size: 12px !important;
		margin-top: 40px;
	}
	
	.confirm-btn2{
		float: right;
		font-size: 12px;
		margin-top: -45px;
	}
	
}

