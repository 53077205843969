/*.bot-avatar {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    background-color:green;
    color: #fff;
    margin-right: 15px;
}
*/
.bot-avatar {
    width: 40px;
    height: 40px;
    margin-right: 8px;
    }