/*@media screen and (max-width: 600px) {
#tContainer {
flex-direction: column;


    }
    #firstCol{
        padding: 25px;
       order: 1;
    }
    #secondCol {
        padding: 15px;
        margin-left: 55px;
        order: 2;

    }
    #thirdCol {
        padding:35px;
        margin-left: 55px;
        order: 3;
        
    }
    #fourthCol {
        padding: 25px;
        margin-left: 10px;
        order: 4;

    }
    #tableSmaller{
width: 1%;
    }
    #bigger{
        width: 230px;
        margin-left: -30%;
    }


}*/

@media only screen and (min-device-width: 300px) and (max-device-width: 310px) {
    #tContainer {
        flex-direction: column;
        
        
            }
            #firstCol{
                padding: 1px !important;
                margin-left: 10px !important;
               order: 1;
            }
            #secondCol {
                padding: 25px;
                margin-left: 45px !important;
                order: 2;
        
            }
            #thirdCol {
                padding:35px;
                margin-left: 36px !important;
                order: 3;
                
            }
            #fourthCol {
                padding: 10px !important;
                margin-left: 10px;
                order: 4;
        
            }
            #tableSmaller{
        width: 1%;
            }
            #bigger{
                width: 147px !important;
                margin-left: -80% !important;
            }
        
    
}
@media only screen and (min-device-width: 311px) and (max-device-width: 315px) {
    #tContainer {
        flex-direction: column;
        
        
            }
            #firstCol{
                padding: 1px !important;
                margin-left: 25px !important;
               order: 1;
            }
            #secondCol {
                padding: 25px;
                margin-left: 53px !important;
                order: 2;
        
            }
            #thirdCol {
                padding:35px;
                margin-left: 47px !important;
                order: 3;
                
            }
            #fourthCol {
                padding: 10px !important;
                margin-left: 25px;
                order: 4;
        
            }
            #tableSmaller{
        width: 1%;
            }
            #bigger{
                width: 160px !important;
                margin-left: -80% !important;
            }
        
    
}
@media only screen and (min-device-width: 316px) and (max-device-width: 320px) {
    #tContainer {
        flex-direction: column;
        
        
            }
            #firstCol{
                padding: 1px !important;
                margin-left: 25px !important;
               order: 1;
            }
            #secondCol {
                padding: 25px;
                margin-left: 53px !important;
                order: 2;
        
            }
            #thirdCol {
                padding:35px;
                margin-left: 47px !important;
                order: 3;
                
            }
            #fourthCol {
                padding: 10px !important;
                margin-left: 25px;
                order: 4;
        
            }
            #tableSmaller{
        width: 1%;
            }
            #bigger{
                width: 160px !important;
                margin-left: -70% !important;
            }
        
    
}
@media only screen and (min-device-width: 321px) and (max-device-width: 325px) {
    #tContainer {
        flex-direction: column;
        
        
            }
            #firstCol{
                padding: 1px !important;
                margin-left: 30px !important;
               order: 1;
            }
            #secondCol {
                padding: 25px;
                margin-left: 58px !important;
                order: 2;
        
            }
            #thirdCol {
                padding:35px;
                margin-left: 53px !important;
                order: 3;
                
            }
            #fourthCol {
                padding: 10px !important;
                margin-left: 25px;
                order: 4;
        
            }
            #tableSmaller{
        width: 1%;
            }
            #bigger{
                width: 170px !important;
                margin-left: -75% !important;
            }
        
    
}
@media only screen and (min-device-width: 326px) and (max-device-width: 330px) {
    #tContainer {
        flex-direction: column;
        
        
            }
            #firstCol{
                padding: 1px !important;
                margin-left: 30px !important;
               order: 1;
            }
            #secondCol {
                padding: 25px;
                margin-left: 58px !important;
                order: 2;
        
            }
            #thirdCol {
                padding:35px;
                margin-left: 53px !important;
                order: 3;
                
            }
            #fourthCol {
                padding: 10px !important;
                margin-left: 25px;
                order: 4;
        
            }
            #tableSmaller{
        width: 1%;
            }
            #bigger{
                width: 170px !important;
                margin-left: -60% !important;
            }
        
    
}

@media only screen and (min-device-width: 331px) and (max-device-width: 340px) {
    #tContainer {
        flex-direction: column;
        
        
            }
            #firstCol{
                padding: 1px;
                margin-left: 27px;
               order: 1;
            }
            #secondCol {
                padding: 25px;
                margin-left: 55px;
                order: 2;
        
            }
            #thirdCol {
                padding:35px;
                margin-left: 50px;
                order: 3;
                
            }
            #fourthCol {
                padding: 25px;
                margin-left: 12px;
                order: 4;
        
            }
            #tableSmaller{
        width: 1%;
            }
            #bigger{
                width: 170px !important;
                margin-left: -50% !important;
            }
}
@media only screen and (min-device-width: 341px) and (max-device-width: 346px) {
    #tContainer {
        flex-direction: column;
        
        
            }
            #firstCol{
                padding: 1px;
                margin-left: 30px;
               order: 1;
            }
            #secondCol {
                padding: 25px;
                margin-left: 55px;
                order: 2;
        
            }
            #thirdCol {
                padding:35px;
                margin-left: 52px;
                order: 3;
                
            }
            #fourthCol {
                padding: 25px;
                margin-left: 14px;
                order: 4;
        
            }
            #tableSmaller{
        width: 1%;
            }
            #bigger{
                width: 180px !important;
                margin-left: -45% !important;
            }
}
@media only screen and (min-device-width: 347px) and (max-device-width: 358px) {
    #tContainer {
        flex-direction: column;
        
        
            }
            #firstCol{
                padding: 1px;
                margin-left: 30px;
               order: 1;
            }
            #secondCol {
                padding: 25px;
                margin-left: 55px;
                order: 2;
        
            }
            #thirdCol {
                padding:35px;
                margin-left: 52px;
                order: 3;
                
            }
            #fourthCol {
                padding: 25px;
                margin-left: 14px;
                order: 4;
        
            }
            #tableSmaller{
        width: 1%;
            }
            #bigger{
                width: 180px !important;
                margin-left: -35% !important;
            }
}

@media only screen and (min-device-width: 359px) and (max-device-width: 370px) {
    #tContainer {
        flex-direction: column;
        
        
            }
            #firstCol{
                padding:1px !important;
                margin-left: 47px !important;
               order: 1;
            }
            #secondCol {
                padding: 25px;
                margin-left: 72px !important;
                order: 2;
        
            }
            #thirdCol {
                padding:35px;
                margin-left: 72px !important;
                order: 3;
                
            }
            #fourthCol {
                padding: 25px;
                margin-left: 30px !important;
                order: 4;
        
            }
            #tableSmaller{
        width: 1%;
            }
            #bigger{
                width: 210px !important;
                margin-left: -45% !important;
            }
}
@media only screen and (min-device-width: 371px) and (max-device-width: 380px) {
    #tContainer {
        flex-direction: column;
        
        
            }
            #firstCol{
                padding:1px !important;
                margin-left: 55px !important;
               order: 1;
            }
            #secondCol {
                padding: 55px !important;
                margin-left: 60px !important;
                order: 2;
        
            }
            #thirdCol {
                padding:15px !important;
                margin-left: 75px !important;
                order: 3;
                
            }
            #fourthCol {
                padding: 55px !important;
                margin-left: 7px !important;
                order: 4;
        
            }
            #tableSmaller{
        width: 1%;
            }
            #bigger{
                width: 230px !important;
                margin-left: -40% !important;
            }
}
@media only screen and (min-device-width: 381px) and (max-device-width: 390px) {
    #tContainer {
        flex-direction: column;
        
        
            }
            #firstCol{
                padding:1px !important;
                margin-left: 55px !important;
               order: 1;
            }
            #secondCol {
                padding: 55px !important;
                margin-left: 60px !important;
                order: 2;
        
            }
            #thirdCol {
                padding:15px !important;
                margin-left: 75px !important;
                order: 3;
                
            }
            #fourthCol {
                padding: 55px !important;
                margin-left: 7px !important;
                order: 4;
        
            }
            #tableSmaller{
        width: 1%;
            }
            #bigger{
                width: 230px !important;
                margin-left: -30% !important;
            }
}
@media only screen and (min-device-width: 391px) and (max-device-width: 400px) {
    #tContainer {
        flex-direction: column;
        
        
            }
            #firstCol{
                padding: 1px !important;
                margin-left: 65px !important;
               order: 1;
            }
            #secondCol {
                padding: 50px !important;
                margin-left: 75px !important;
                order: 2;
        
            }
            #thirdCol {
                padding:15px !important;
                margin-left: 85px !important;
                order: 3;
                
            }
            #fourthCol {
                padding: 55px !important;
                margin-left: 20px !important;
                order: 4;
        
            }
            #tableSmaller{
        width: 1%;
            }
            #bigger{
                width: 240px !important;
                margin-left: -35% !important;
            }
}
@media only screen and (min-device-width: 401px) and (max-device-width: 410px) {
    #tContainer {
        flex-direction: column;
        
        
            }
            #firstCol{
                padding: 1px !important;
                margin-left: 70px !important;
               order: 1;
            }
            #secondCol {
                padding: 50px !important;
                margin-left: 80px !important;
                order: 2;
        
            }
            #thirdCol {
                padding:15px !important;
                margin-left: 85px !important;
                order: 3;
                
            }
            #fourthCol {
                padding: 55px !important;
                margin-left: 25px !important;
                order: 4;
        
            }
            #tableSmaller{
        width: 1%;
            }
            #bigger{
                width: 260px !important;
                margin-left: -30% !important;
            }
}
@media only screen and (min-device-width: 411px) and (max-device-width: 420px) {
    #tContainer {
        flex-direction: column;
        
        
            }
            #firstCol{
                padding: 1px !important;
                margin-left: 75px !important;
               order: 1;
            }
            #secondCol {
                padding: 50px !important;
                margin-left: 85px !important;
                order: 2;
        
            }
            #thirdCol {
                padding:15px !important;
                margin-left: 90px !important;
                order: 3;
                
            }
            #fourthCol {
                padding: 55px !important;
                margin-left: 27px !important;
                order: 4;
        
            }
            #tableSmaller{
        width: 1%;
            }
            #bigger{
                width: 260px !important;
                margin-left: -30% !important;
            }
}
@media only screen and (min-device-width: 421px) and (max-device-width: 430px) {
    #tContainer {
        flex-direction: column;
        
        
            }
            #firstCol{
                padding: 1px !important;
                margin-left: 75px !important;
               order: 1;
            }
            #secondCol {
                padding: 50px !important;
                margin-left: 85px !important;
                order: 2;
        
            }
            #thirdCol {
                padding:15px !important;
                margin-left: 90px !important;
                order: 3;
                
            }
            #fourthCol {
                padding: 55px !important;
                margin-left: 30px !important;
                order: 4;
        
            }
            #tableSmaller{
        width: 1%;
            }
            #bigger{
                width: 270px !important;
                margin-left: -25% !important;
            }
}
@media only screen and (min-device-width: 431px) and (max-device-width: 450px) {
    #tContainer {
        flex-direction: column;
        
        
            }
            #firstCol{
                padding: 1px !important;
                margin-left: 85px !important;
               order: 1;
            }
            #secondCol {
                padding: 50px !important;
                margin-left: 93px !important;
                order: 2;
        
            }
            #thirdCol {
                padding:15px !important;
                margin-left: 95px !important;
                order: 3;
                
            }
            #fourthCol {
                padding: 55px !important;
                margin-left: 37px !important;
                order: 4;
        
            }
            #tableSmaller{
        width: 1%;
            }
            #bigger{
                width: 275px !important;
                margin-left: -25% !important;
            }
}
@media only screen and (min-device-width: 451px) and (max-device-width: 470px) {
    #tContainer {
        flex-direction: column;
        
        
            }
            #firstCol{
                padding: 1px !important;
                margin-left: 85px !important;
               order: 1;
            }
            #secondCol {
                padding: 50px !important;
                margin-left: 100px !important;
                order: 2;
        
            }
            #thirdCol {
                padding:15px !important;
                margin-left: 100px !important;
                order: 3;
                
            }
            #fourthCol {
                padding: 55px !important;
                margin-left: 40px !important;
                order: 4;
        
            }
            #tableSmaller{
        width: 1%;
            }
            #bigger{
                width: 290px !important;
                margin-left: -20% !important;
            }
}
@media only screen and (min-device-width: 471px) and (max-device-width: 500px) {
    #tContainer {
        flex-direction: column;
        
        
            }
            #firstCol{
                padding: 1px !important;
                margin-left: 85px !important;
               order: 1;
            }
            #secondCol {
                padding: 50px !important;
                margin-left: 100px !important;
                order: 2;
        
            }
            #thirdCol {
                padding:15px !important;
                margin-left: 100px !important;
                order: 3;
                
            }
            #fourthCol {
                padding: 55px !important;
                margin-left: 37px !important;
                order: 4;
        
            }
            #tableSmaller{
        width: 1%;
            }
            #bigger{
                width: 310px !important;
                margin-left: -15% !important;
            }


}
@media only screen and (min-device-width: 501px) and (max-device-width: 530px) {
    #tContainer {
        flex-direction: column;
        
        
            }
            #firstCol{
                padding: 1px !important;
                margin-left: 105px !important;
               order: 1;
            }
            #secondCol {
                padding: 50px !important;
                margin-left: 115px !important;
                order: 2;
        
            }
            #thirdCol {
                padding:15px !important;
                margin-left: 115px !important;
                order: 3;
                
            }
            #fourthCol {
                padding: 55px !important;
                margin-left: 60px !important;
                order: 4;
        
            }
            #tableSmaller{
        width: 1%;
            }
            #bigger{
                width: 340px !important;
                margin-left: -15% !important;
            }


}
@media only screen and (min-device-width: 531px) and (max-device-width: 560px) {
    #tContainer {
        flex-direction: column;
        
        
            }
            #firstCol{
                padding: 1px !important;
                margin-left: 125px !important;
               order: 1;
            }
            #secondCol {
                padding: 50px !important;
                margin-left: 125px !important;
                order: 2;
        
            }
            #thirdCol {
                padding:15px !important;
                margin-left: 125px !important;
                order: 3;
                
            }
            #fourthCol {
                padding: 55px !important;
                margin-left: 80px !important;
                order: 4;
        
            }
            #tableSmaller{
        width: 1%;
            }
            #bigger{
                width: 375px !important;
                margin-left: -15% !important;
            }


}
@media only screen and (min-device-width: 561px) and (max-device-width: 590px) {
    #tContainer {
        flex-direction: column;
        
        
            }
            #firstCol{
                padding: 1px !important;
                margin-left: 145px !important;
               order: 1;
            }
            #secondCol {
                padding: 50px !important;
                margin-left: 135px !important;
                order: 2;
        
            }
            #thirdCol {
                padding:15px !important;
                margin-left: 135px !important;
                order: 3;
                
            }
            #fourthCol {
                padding: 55px !important;
                margin-left: 100px !important;
                order: 4;
        
            }
            #tableSmaller{
        width: 1%;
            }
            #bigger{
                width: 415px !important;
                margin-left: -15% !important;
            }


}
@media only screen and (min-device-width: 591px) and (max-device-width: 620px) {
    #tContainer {
        flex-direction: column;
        
        
            }
            #firstCol{
                padding: 1px !important;
                margin-left: 165px !important;
               order: 1;
            }
            #secondCol {
                padding: 50px !important;
                margin-left: 150px !important;
                order: 2;
        
            }
            #thirdCol {
                padding:15px !important;
                margin-left: 150px !important;
                order: 3;
                
            }
            #fourthCol {
                padding: 55px !important;
                margin-left: 117px !important;
                order: 4;
        
            }
            #tableSmaller{
        width: 1%;
            }
            #bigger{
                width: 445px !important;
                margin-left: -15% !important;
            }


}
@media only screen and (min-device-width: 621px) and (max-device-width: 650px) {
    #tContainer {
        flex-direction: column;
        
        
            }
            #firstCol{
                padding: 1px !important;
                margin-left: 180px !important;
               order: 1;
            }
            #secondCol {
                padding: 50px !important;
                margin-left: 160px !important;
                order: 2;
        
            }
            #thirdCol {
                padding:15px !important;
                margin-left: 160px !important;
                order: 3;
                
            }
            #fourthCol {
                padding: 55px !important;
                margin-left: 132px !important;
                order: 4;
        
            }
            #tableSmaller{
        width: 1%;
            }
            #bigger{
                width: 485px !important;
                margin-left: -15% !important;
            }


}
@media only screen and (min-device-width: 651px) and (max-device-width: 680px){
    #tContainer {
        flex-direction: column;
        
        
            }
            #firstCol{
                padding: 1px !important;
                margin-left: 210px !important;
               order: 1;
            }
            #secondCol {
                padding: 50px !important;
                margin-left: 180px !important;
                order: 2;
        
            }
            #thirdCol {
                padding:15px !important;
                margin-left: 180px !important;
                order: 3;
                
            }
            #fourthCol {
                padding: 55px !important;
                margin-left: 165px !important;
                order: 4;
        
            }
            #tableSmaller{
        width: 1%;
            }
            #bigger{
                width: 535px !important;
                margin-left: -15% !important;
            }

}
@media only screen and (min-device-width: 681px) and (max-device-width: 710px){
    #tContainer {
        flex-direction: column;
        
        
            }
            #firstCol{
                padding: 1px !important;
                margin-left: 225px !important;
               order: 1;
            }
            #secondCol {
                padding: 50px !important;
                margin-left: 190px !important;
                order: 2;
        
            }
            #thirdCol {
                padding:15px !important;
                margin-left: 190px !important;
                order: 3;
                
            }
            #fourthCol {
                padding: 55px !important;
                margin-left: 175px !important;
                order: 4;
        
            }
            #tableSmaller{
        width: 1%;
            }
            #bigger{
                width: 565px !important;
                margin-left: -15% !important;
            }

}
@media only screen and (min-device-width: 711px) and (max-device-width: 740px){
    #tContainer {
        flex-direction: column;
        
        
            }
            #firstCol{
                padding: 1px !important;
                margin-left: 240px !important;
               order: 1;
            }
            #secondCol {
                padding: 50px !important;
                margin-left: 200px !important;
                order: 2;
        
            }
            #thirdCol {
                padding:15px !important;
                margin-left: 200px !important;
                order: 3;
                
            }
            #fourthCol {
                padding: 55px !important;
                margin-left: 190px !important;
                order: 4;
        
            }
            #tableSmaller{
        width: 1%;
            }
            #bigger{
                width: 595px !important;
                margin-left: -15% !important;
            }

}
@media only screen and (min-device-width: 741px) and (max-device-width: 767px){
    #tContainer {
        flex-direction: column;
        
        
            }
            #firstCol{
                padding: 1px !important;
                margin-left: 240px !important;
               order: 1;
            }
            #secondCol {
                padding: 50px !important;
                margin-left: 200px !important;
                order: 2;
        
            }
            #thirdCol {
                padding:15px !important;
                margin-left: 200px !important;
                order: 3;
                
            }
            #fourthCol {
                padding: 55px !important;
                margin-left: 190px !important;
                order: 4;
        
            }
            #tableSmaller{
        width: 1%;
            }
            #bigger{
                width: 595px !important;
                margin-left: -10% !important;
            }

}

@media only screen and (min-device-width: 768px) and (max-device-width: 800px){
    #tContainer {
        display: grid !important;
        grid-template-columns: 210px 210px 210px !important;
        gap: 30px !important;
        
          }
    #tableSmaller{
        width: 100%;
            }
            #bigger{
                width: 625px !important;
                margin-left: -9% !important;
            }
}

@media only screen and (min-device-width: 801px) and (max-device-width: 830px){

    #tContainer {
        display: grid !important;
        grid-template-columns: 230px 230px 230px !important;
        gap: 30px !important;
        
          }
    #tableSmaller{
        width: 100%;
            }
            #bigger{
                width: 660px !important;
                margin-left: -9% !important;
            }
}
@media only screen and (min-device-width: 831px) and (max-device-width: 860px){
    #tContainer {
        display: grid !important;
        grid-template-columns: 250px 250px 250px !important;
        gap: 30px !important;
        
          }
    #tableSmaller{
        width: 100%;
            }
            #bigger{
                width: 700px !important;
                margin-left: -9% !important;
            }
}

@media only screen and (min-device-width: 861px) and (max-device-width: 870px){
    #tContainer {
        display: grid !important;
        grid-template-columns: 260px 260px 260px !important;
        gap: 30px !important;
        
          }
    #tableSmaller{
        width: 100%;
            }
            #bigger{
                width: 710px !important;
                margin-left: -9% !important;
            }
}
@media only screen and (min-device-width: 871px) and (max-device-width: 900px){
    #tContainer {
        display: grid !important;
        grid-template-columns: 260px 260px 260px !important;
        gap: 30px !important;
        
          }
    #tableSmaller{
        width: 100%;
            }
            #bigger{
                width: 750px !important;
                margin-left: -9% !important;
            }
}
@media only screen and (min-device-width: 901px) and (max-device-width: 930px){
    #tContainer {
        display: grid !important;
        grid-template-columns: 260px 260px 260px !important;
        gap: 30px !important;
        
          }
    #tableSmaller{
        width: 100%;
            }
            #bigger{
                width: 767px !important;
                margin-left: -8% !important;
            }
}
@media only screen and (min-device-width: 931px) and (max-device-width: 955px){
    #tContainer {
        display: grid !important;
        grid-template-columns: 260px 260px 260px !important;
        gap: 30px !important;
        
          }
    #tableSmaller{
        width: 100%;
            }
            #bigger{
                width: 790px !important;
                margin-left: -7% !important;
            }
}
@media only screen and (min-device-width: 956px) and (max-device-width: 975px){
    #tContainer {
        display: grid !important;
        grid-template-columns: 260px 260px 260px !important;
        gap: 30px !important;
        
          }
    #tableSmaller{
        width: 100%;
            }
            #bigger{
                width: 805px !important;
                margin-left: -6% !important;
            }
}
@media only screen and (min-device-width: 976px) and (max-device-width: 990px){
    #tContainer {
        display: grid !important;
        grid-template-columns: 260px 260px 260px !important;
        gap: 30px !important;
        
          }
    #tableSmaller{
        width: 100%;
            }
            #bigger{
                width: 825px !important;
                margin-left: -6% !important;
            }
}

@media only screen and (min-device-width: 991px) and (max-device-width: 1024px){
    #tContainer {
        display: grid !important;
        grid-template-columns: 200px 200px 200px !important;
        gap: 30px !important;
        
          }
    #tableSmaller{
        width: 100%;
            }
            #bigger{
                width: 615px !important;
                margin-left: -12% !important;
            }
}
@media only screen and (min-device-width: 1025px) and (max-device-width: 1070px){
    #tContainer {
        display: grid !important;
        grid-template-columns: 200px 200px 200px !important;
        gap: 30px !important;
        
          }
    #tableSmaller{
        width: 100%;
            }
            #bigger{
                width: 615px !important;
                margin-left: -9% !important;
            }
}

@media only screen and (min-device-width: 1071px) and (max-device-width: 1100px){
    #tContainer {
        display: grid !important;
        grid-template-columns: 220px 220px 220px !important;
        gap: 30px !important;
        
          }
    #tableSmaller{
        width: 100%;
            }
            #bigger{
                width: 655px !important;
                margin-left: -9% !important;
            }
}
@media only screen and (min-device-width: 1101px) and (max-device-width: 1140px){
    #tContainer {
        display: grid !important;
        grid-template-columns: 250px 250px 250px !important;
        gap: 30px !important;
        
          }
    #tableSmaller{
        width: 100%;
            }
            #bigger{
                width: 695px !important;
                margin-left: -9% !important;
            }
}
@media only screen and (min-device-width: 1141px) and (max-device-width: 1180px){
    #tContainer {
        display: grid !important;
        grid-template-columns: 250px 250px 250px !important;
        gap: 30px !important;
        
          }
    #tableSmaller{
        width: 100%;
            }
            #bigger{
                width: 715px !important;
                margin-left: -6% !important;
            }
}

@media only screen and (min-device-width: 1181px) and (max-device-width: 1210px){
    #tContainer {
        display: grid !important;
        grid-template-columns: 250px 250px 250px !important;
        gap: 30px !important;
        
          }
    #tableSmaller{
        width: 100%;
            }
            #bigger{
                width: 755px !important;
                margin-left: -6% !important;
            }
}

@media only screen and (min-device-width: 1211px) and (max-device-width: 1240px){
    #tContainer {
        display: grid !important;
        grid-template-columns: 250px 250px 250px !important;
        gap: 30px !important;
        
          }
    #tableSmaller{
        width: 100%;
            }
            #bigger{
                width: 790px !important;
                margin-left: -6% !important;
            }
}
@media only screen and (min-device-width: 1241px) and (max-device-width: 1270px){
    #tContainer {
        display: grid !important;
        grid-template-columns: 200px 200px 200px 200px !important;
        gap: 30px !important;
        
          }
    #tableSmaller{
        width: 100%;
            }
            #bigger{
                width: 820px !important;
                margin-left: -6% !important;
            }
}
@media only screen and (min-device-width: 1271px) and (max-device-width: 1300px){
    #tContainer {
        display: grid !important;
        grid-template-columns: 200px 200px 200px 200px !important;
        gap: 30px !important;
        
          }
    #tableSmaller{
        width: 100%;
            }
            #bigger{
                width: 865px !important;
                margin-left: -6% !important;
            }
}
@media only screen and (min-device-width: 1301px) and (max-device-width: 1330px){
    #tContainer {
        display: grid !important;
        grid-template-columns: 230px 230px 230px 230px !important;
        gap: 30px !important;
        
          }
    #tableSmaller{
        width: 100%;
            }
            #bigger{
                width: 895px !important;
                margin-left: -6% !important;
            }
}
