/*@media screen and (max-width: 600px) {
#tContainer2 {
flex-direction: column;


    }
    #firstCol2{
        padding: 25px;
       order: 1;
    }
    #secondCol2 {
        padding: 15px;
        margin-left: 55px;
        order: 2;

    }
    #thirdCol2 {
        padding:35px;
        margin-left: 55px;
        order: 3;
        
    }
    #fourthCol2 {
        padding: 25px;
        margin-left: 10px;
        order: 4;

    }
    #tableSmaller2{
width: 1%;
    }
    #bigger2{
        width: 230px;
        margin-left: -30%;
    }


}*/
@media only screen and (min-device-width: 300px) and (max-device-width: 319px) {
     #tContainer2 {
        flex-direction: column;
        
        
            }
            #firstCol2{
                padding: 1px !important;
                margin-left:40px;
               order: 1;
            }
            #secondCol2 {
                padding: 45px;
                margin-left: 60px !important;
                order: 2;
        
            }
            #thirdCol2 {
                padding:35px;
                margin-left: 70px !important;
                order: 3;
                
            }
            #fourthCol2 {
                padding: 30px !important;
                margin-left: 20px;
                order: 4;
        
            }
    #theDropDown{
        margin-left: -30%;  
    }


            #tableSmaller2{
        width: 1%;
            }
            #bigger2{
                width: 200px !important;
                margin-left: -60% !important;
                left: 30px !important;
            }
        
    
}

@media only screen and (min-device-width: 320px) and (max-device-width: 330px) {
    #tContainer2 {
        flex-direction: column;
        
        
            }
            #firstCol2{
                padding: 1px !important;
                margin-left:60px;
               order: 1;
            }
            #secondCol2 {
                padding: 45px;
                margin-left: 75px !important;
                order: 2;
        
            }
            #thirdCol2 {
                padding:35px;
                margin-left: 80px !important;
                order: 3;
                
            }
            #fourthCol2 {
                padding: 45px !important;
                margin-left: 25px;
                order: 4;
        
            }

    #theDropDown{
        margin-left: -20%;  
    }
 

            #tableSmaller2{
        width: 1%;
            }
            #bigger2{
                width: 230px !important;
                margin-left: -65% !important;
            }
        
    
}

@media only screen and (min-device-width: 331px) and (max-device-width: 358px) {
    #tContainer2 {
        flex-direction: column;
        
        
            }
            #firstCol2{
                padding: 1px !important;
                margin-left:65px;
               order: 1;
            }
            #secondCol2 {
                padding: 45px;
                margin-left: 80px !important;
                order: 2;
        
            }
            #thirdCol2 {
                padding:35px;
                margin-left: 85px !important;
                order: 3;
                
            }
            #fourthCol2 {
                padding: 45px !important;
                margin-left: 30px;
                order: 4;
        
            }
 
    #theDropDown{
        margin-left: -10%;  
    }
 

            #tableSmaller2{
        width: 1%;
            }
            #bigger2{
                width: 240px !important;
                margin-left: -55% !important;
            }
        
    
}

@media only screen and (min-device-width: 359px) and (max-device-width: 370px) {
    #tContainer2 {
        flex-direction: column;
        
        
            }
            #firstCol2{
                padding: 1px !important;
                margin-left:65px;
               order: 1;
            }
            #secondCol2 {
                padding: 45px;
                margin-left: 80px !important;
                order: 2;
        
            }
            #thirdCol2 {
                padding:35px;
                margin-left: 83px !important;
                order: 3;
                
            }
            #fourthCol2 {
                padding: 45px !important;
                margin-left: 30px;
                order: 4;
        
            }
 
    #theDropDown{
        margin-left: -3%;  
    }
 

            #tableSmaller2{
        width: 1%;
            }
            #bigger2{
                width: 245px !important;
                margin-left: -35% !important;
            }
        
    
}
@media only screen and (min-device-width: 371px) and (max-device-width: 380px) {
 
    #tContainer2 {
        flex-direction: column;
        
        
            }
            #firstCol2{
                padding: 1px !important;
                margin-left:75px !important;
               order: 1;
            }
            #secondCol2 {
                padding: 45px;
                margin-left: 87px !important;
                order: 2;
        
            }
            #thirdCol2 {
                padding:35px;
                margin-left: 87px !important;
                order: 3;
                
            }
            #fourthCol2 {
                padding: 45px !important;
                margin-left: 40px !important;
                order: 4;
        
            }
 
    #tableSmaller2{
        width: 1%;
            }
            #bigger2{
                width: 265px !important;
                margin-left: -35% !important;
            }
        
    
}
@media only screen and (min-device-width: 381px) and (max-device-width: 390px) {
 
    #tContainer2 {
        flex-direction: column;
        
        
            }
            #firstCol2{
                padding: 1px !important;
                margin-left:75px !important;
               order: 1;
            }
            #secondCol2 {
                padding: 45px;
                margin-left: 87px !important;
                order: 2;
        
            }
            #thirdCol2 {
                padding:35px;
                margin-left: 87px !important;
                order: 3;
                
            }
            #fourthCol2 {
                padding: 45px !important;
                margin-left: 40px !important;
                order: 4;
        
            }
 
    #tableSmaller2{
        width: 1%;
            }
            #bigger2{
                width: 265px !important;
                margin-left: -30% !important;
            }
        
    
}
@media only screen and (min-device-width: 391px) and (max-device-width: 400px) {

    #tContainer2 {
        flex-direction: column;
        
        
            }
            #firstCol2{
                padding: 1px !important;
                margin-left:75px !important;
               order: 1;
            }
            #secondCol2 {
                padding: 45px;
                margin-left: 85px !important;
                order: 2;
        
            }
            #thirdCol2 {
                padding:35px;
                margin-left: 85px !important;
                order: 3;
                
            }
            #fourthCol2 {
                padding: 45px !important;
                margin-left: 40px !important;
                order: 4;
        
            }
    #tableSmaller2{
        width: 1%;
            }
            #bigger2{
                width: 270px !important;
                margin-left: -25% !important;
            }
        
    
}
@media only screen and (min-device-width: 401px) and (max-device-width: 410px) {

    #tContainer2 {
        flex-direction: column;
        
        
            }
            #firstCol2{
                padding: 1px !important;
                margin-left:75px !important;
               order: 1;
            }
            #secondCol2 {
                padding: 45px;
                margin-left: 85px !important;
                order: 2;
        
            }
            #thirdCol2 {
                padding:35px;
                margin-left: 85px !important;
                order: 3;
                
            }
            #fourthCol2 {
                padding: 45px !important;
                margin-left: 40px !important;
                order: 4;
        
            }
    #tableSmaller2{
        width: 1%;
            }
            #bigger2{
                width: 270px !important;
                margin-left: -20% !important;
            }
        
    
}
@media only screen and (min-device-width: 411px) and (max-device-width: 420px) {

    #tContainer2 {
        flex-direction: column;
        
        
            }
            #firstCol2{
                padding: 1px !important;
                margin-left:75px !important;
               order: 1;
            }
            #secondCol2 {
                padding: 45px;
                margin-left: 85px !important;
                order: 2;
        
            }
            #thirdCol2 {
                padding:35px;
                margin-left: 85px !important;
                order: 3;
                
            }
            #fourthCol2 {
                padding: 45px !important;
                margin-left: 40px !important;
                order: 4;
        
            }
    #tableSmaller2{
        width: 1%;
            }
            #bigger2{
                width: 270px !important;
                margin-left: -16% !important;
            }
        
    
}
@media only screen and (min-device-width: 421px) and (max-device-width: 440px) {
    #tContainer2 {
        flex-direction: column;
        
        
            }
            #firstCol2{
                padding: 1px !important;
                margin-left:85px !important;
               order: 1;
            }
            #secondCol2 {
                padding: 45px;
                margin-left: 95px !important;
                order: 2;
        
            }
            #thirdCol2 {
                padding:35px;
                margin-left: 97px !important;
                order: 3;
                
            }
            #fourthCol2 {
                padding: 45px !important;
                margin-left: 50px !important;
                order: 4;
        
            }
 
    #tableSmaller2{
        width: 1%;
            }
            #bigger2{
                width: 280px !important;
                margin-left: -20% !important;
            }
        
    
}
@media only screen and (min-device-width: 441px) and (max-device-width: 450px) {
 
    #tContainer2 {
        flex-direction: column;
        
        
            }
            #firstCol2{
                padding: 1px !important;
                margin-left:90px !important;
               order: 1;
            }
            #secondCol2 {
                padding: 45px;
                margin-left: 100px !important;
                order: 2;
        
            }
            #thirdCol2 {
                padding:35px;
                margin-left: 101px !important;
                order: 3;
                
            }
            #fourthCol2 {
                padding: 45px !important;
                margin-left: 55px !important;
                order: 4;
        
            }
    #tableSmaller2{
        width: 1%;
            }
            #bigger2{
                width: 300px !important;
                margin-left: -15% !important;
            }
        
    
}
@media only screen and (min-device-width: 451px) and (max-device-width: 470px) {
    #tContainer2 {
        flex-direction: column;
        
        
            }
            #firstCol2{
                padding: 1px !important;
                margin-left:100px !important;
               order: 1;
            }
            #secondCol2 {
                padding: 45px;
                margin-left: 105px !important;
                order: 2;
        
            }
            #thirdCol2 {
                padding:35px;
                margin-left: 106px !important;
                order: 3;
                
            }
            #fourthCol2 {
                padding: 45px !important;
                margin-left: 63px !important;
                order: 4;
        
            }
 
    #tableSmaller2{
        width: 1%;
            }
            #bigger2{
                width: 320px !important;
                margin-left: -17% !important;
            }
        
    
}
@media only screen and (min-device-width: 471px) and (max-device-width: 500px) {
    #tContainer2 {
        flex-direction: column;
        
        
            }
            #firstCol2{
                padding: 1px !important;
                margin-left:115px !important;
               order: 1;
            }
            #secondCol2 {
                padding: 45px;
                margin-left: 115px !important;
                order: 2;
        
            }
            #thirdCol2 {
                padding:35px;
                margin-left: 115px !important;
                order: 3;
                
            }
            #fourthCol2 {
                padding: 45px !important;
                margin-left: 79px !important;
                order: 4;
        
            }

    #tableSmaller2{
        width: 1%;
            }
            #bigger2{
                width: 345px !important;
                margin-left: -17% !important;
            }
        
    
}
@media only screen and (min-device-width: 501px) and (max-device-width: 530px) {
    #tContainer2 {
        flex-direction: column;
        
        
            }
            #firstCol2{
                padding: 1px !important;
                margin-left:130px !important;
               order: 1;
            }
            #secondCol2 {
                padding: 45px;
                margin-left: 125px !important;
                order: 2;
        
            }
            #thirdCol2 {
                padding:35px;
                margin-left: 125px !important;
                order: 3;
                
            }
            #fourthCol2 {
                padding: 45px !important;
                margin-left: 96px !important;
                order: 4;
        
            }
 
    #tableSmaller2{
        width: 1%;
            }
            #bigger2{
                width: 385px !important;
                margin-left: -17% !important;
            }
        
    
}
@media only screen and (min-device-width: 531px) and (max-device-width: 560px) {
 
    #tContainer2 {
        flex-direction: column;
        
        
            }
            #firstCol2{
                padding: 1px !important;
                margin-left:135px !important;
               order: 1;
            }
            #secondCol2 {
                padding: 45px;
                margin-left: 125px !important;
                order: 2;
        
            }
            #thirdCol2 {
                padding:35px;
                margin-left: 125px !important;
                order: 3;
                
            }
            #fourthCol2 {
                padding: 45px !important;
                margin-left: 101px !important;
                order: 4;
        
            }
    #tableSmaller2{
        width: 1%;
            }
            #bigger2{
                width: 400px !important;
                margin-left: -11% !important;
            }
        
    
}
@media only screen and (min-device-width: 561px) and (max-device-width: 574px) {
    #tableSmaller2{
        width: 1%;
            }
            #bigger2{
                width: 420px !important;
                margin-left: -9% !important;
            }
        
    
}
@media only screen and (min-device-width: 575px) and (max-device-width: 590px) {
  
    #tContainer2 {
        flex-direction: column;
        
        
            }
            #firstCol2{
                padding: 1px !important;
                margin-left:160px !important;
               order: 1;
            }
            #secondCol2 {
                padding: 45px;
                margin-left: 145px !important;
                order: 2;
        
            }
            #thirdCol2 {
                padding:35px;
                margin-left: 145px !important;
                order: 3;
                
            }
            #fourthCol2 {
                padding: 45px !important;
                margin-left: 125px !important;
                order: 4;
        
            }
 
    #theDropDown{
        margin-right: -8% !important;  
    }

    #tableSmaller2{
        width: 1%;
            }
            #bigger2{
                width: 440px !important;
                margin-left: -9% !important;
            }
        
    
}
@media only screen and (min-device-width: 591px) and (max-device-width: 620px) {
    #tContainer2 {
        flex-direction: column;
        
        
            }
            #firstCol2{
                padding: 1px !important;
                margin-left:165px !important;
               order: 1;
            }
            #secondCol2 {
                padding: 45px;
                margin-left: 150px !important;
                order: 2;
        
            }
            #thirdCol2 {
                padding:35px;
                margin-left: 150px !important;
                order: 3;
                
            }
            #fourthCol2 {
                padding: 45px !important;
                margin-left: 130px !important;
                order: 4;
        
            }
 
    #theDropDown{
        margin-right: -8% !important;  
    }
  
    #tableSmaller2{
        width: 1%;
            }
            #bigger2{
                width: 455px !important;
                margin-left: -8% !important;
            }
        
    
}
@media only screen and (min-device-width: 621px) and (max-device-width: 650px) {
    #tContainer2 {
        flex-direction: column;
        
        
            }
            #firstCol2{
                padding: 1px !important;
                margin-left:170px !important;
               order: 1;
            }
            #secondCol2 {
                padding: 45px;
                margin-left: 150px !important;
                order: 2;
        
            }
            #thirdCol2 {
                padding:35px;
                margin-left: 150px !important;
                order: 3;
                
            }
            #fourthCol2 {
                padding: 45px !important;
                margin-left: 135px !important;
                order: 4;
        
            }
 
    #theDropDown{
        margin-right: -8% !important;  
    }
    #tableSmaller2{
        width: 1%;
            }
            #bigger2{
                width: 465px !important;
                margin-left: -6% !important;
            }
        
    
}
@media only screen and (min-device-width: 651px) and (max-device-width: 680px){
    #tContainer2 {
        flex-direction: column;
        
        
            }
            #firstCol2{
                padding: 1px !important;
                margin-left:190px !important;
               order: 1;
            }
            #secondCol2 {
                padding: 45px;
                margin-left: 165px !important;
                order: 2;
        
            }
            #thirdCol2 {
                padding:35px;
                margin-left: 165px !important;
                order: 3;
                
            }
            #fourthCol2 {
                padding: 45px !important;
                margin-left: 155px !important;
                order: 4;
        
            }
 
    #theDropDown{
        margin-right: -8% !important;  
    }
            #tableSmaller2{
        width: 1%;
            }
            #bigger2{
                width: 500px !important;
                margin-left: -7% !important;
            }

}
@media only screen and (min-device-width: 681px) and (max-device-width: 710px){
    #tContainer2 {
        flex-direction: column;
        
        
            }
            #firstCol2{
                padding: 1px !important;
                margin-left:200px !important;
               order: 1;
            }
            #secondCol2 {
                padding: 45px;
                margin-left: 167px !important;
                order: 2;
        
            }
            #thirdCol2 {
                padding:35px;
                margin-left: 168px !important;
                order: 3;
                
            }
            #fourthCol2 {
                padding: 45px !important;
                margin-left: 164px !important;
                order: 4;
        
            }
 
    #theDropDown{
        margin-right: -8% !important;  
    }
    #tableSmaller2{
        width: 1%;
            }
            #bigger2{
                width: 540px !important;
                margin-left: -6% !important;
            }

}
    @media only screen and (min-device-width: 711px) and (max-device-width: 740px){
        #tContainer2 {
            flex-direction: column;
            
            
                }
                #firstCol2{
                    padding: 1px !important;
                    margin-left:230px !important;
                   order: 1;
                }
                #secondCol2 {
                    padding: 45px;
                    margin-left: 190px !important;
                    order: 2;
            
                }
                #thirdCol2 {
                    padding:35px;
                    margin-left: 190px !important;
                    order: 3;
                    
                }
                #fourthCol2 {
                    padding: 45px !important;
                    margin-left: 194px !important;
                    order: 4;
            
                }
     
        #theDropDown{
            margin-right: -8% !important;  
        }
    #tableSmaller2{
        width: 1%;
            }
            #bigger2{
                width: 575px !important;
                margin-left: -7% !important;
            }

}
@media only screen and (min-device-width: 741px) and (max-device-width: 767px){
    #tContainer2 {
        flex-direction: column;
        
        
            }
            #firstCol2{
                padding: 1px !important;
                margin-left:245px !important;
               order: 1;
            }
            #secondCol2 {
                padding: 45px;
                margin-left: 200px !important;
                order: 2;
        
            }
            #thirdCol2 {
                padding:35px;
                margin-left: 200px !important;
                order: 3;
                
            }
            #fourthCol2 {
                padding: 45px !important;
                margin-left: 209px !important;
                order: 4;
        
            }
 
    #theDropDown{
        margin-right: -8% !important;  
    }
    #tableSmaller2{
        width: 1%;
            }
            #bigger2{
                width: 607px !important;
                margin-left: -7% !important;
            }

}

@media only screen and (min-device-width: 768px) and (max-device-width: 800px){

    #tContainer2 {
        flex-direction: column;
        
        
            }
            #firstCol2{
                padding: 1px !important;
                margin-left:275px !important;
               order: 1;
            }
            #secondCol2 {
                padding: 45px;
                margin-left: 250px !important;
                order: 2;
        
            }
            #thirdCol2 {
                padding:35px;
                margin-left: 250px !important;
                order: 3;
                
            }
            #fourthCol2 {
                padding: 45px !important;
                margin-left: 240px !important;
                order: 4;
        
            }
 
    #theDropDown{
        margin-right: -5% !important;  
    }
    #tableSmaller2{
        width: 1%;
            }
            #bigger2{
               width: 635px !important;
                margin-left: -9% !important;
            }
            #greenRowManipulationToFitMobileView {

                display: grid !important;
                grid-template-columns: 170px 170px 170px 170px !important;
            }


}

@media only screen and (min-device-width: 801px) and (max-device-width: 830px){

    #tContainer2 {
        flex-direction: column;
        
        
            }
            #firstCol2{
                padding: 1px !important;
                margin-left:285px !important;
               order: 1;
            }
            #secondCol2 {
                padding: 45px;
                margin-left: 260px !important;
                order: 2;
        
            }
            #thirdCol2 {
                padding:35px;
                margin-left: 260px !important;
                order: 3;
                
            }
            #fourthCol2 {
                padding: 45px !important;
                margin-left: 247px !important;
                order: 4;
        
            }
 
    #theDropDown{
        margin-right: -5% !important;  
    }
    #greenRowManipulationToFitMobileView {

        display: grid !important;
        grid-template-columns: 170px 170px 170px 170px !important;
    }
    #tableSmaller2{
        width: 1%;
            }
            #bigger2{
                width: 660px !important;
                margin-left: -9% !important;
            }

            
}
@media only screen and (min-device-width: 831px) and (max-device-width: 860px){

    #tContainer2 {
        flex-direction: column;
        
        
            }
            #firstCol2{
                padding: 1px !important;
                margin-left:297px !important;
               order: 1;
            }
            #secondCol2 {
                padding: 45px;
                margin-left: 267px !important;
                order: 2;
        
            }
            #thirdCol2 {
                padding:35px;
                margin-left: 267px !important;
                order: 3;
                
            }
            #fourthCol2 {
                padding: 45px !important;
                margin-left: 264px !important;
                order: 4;
        
            }
 
    #theDropDown{
        margin-right: -5% !important;  
    }
    #greenRowManipulationToFitMobileView {

        display: grid !important;
        grid-template-columns: 170px 170px 170px 170px !important;
    }
    #tableSmaller2{
        width: 1%;
            }
            #bigger2{
                width: 680px !important;
                margin-left: -9% !important;
            }
}

@media only screen and (min-device-width: 861px) and (max-device-width: 870px){

    #tContainer2 {
        flex-direction: column;
        
        
            }
            #firstCol2{
                padding: 1px !important;
                margin-left:312px !important;
               order: 1;
            }
            #secondCol2 {
                padding: 45px;
                margin-left: 280px !important;
                order: 2;
        
            }
            #thirdCol2 {
                padding:35px;
                margin-left: 280px !important;
                order: 3;
                
            }
            #fourthCol2 {
                padding: 45px !important;
                margin-left: 277px !important;
                order: 4;
        
            }
 
    #theDropDown{
        margin-right: -5% !important;  
    }
    #greenRowManipulationToFitMobileView {

        display: grid !important;
        grid-template-columns: 170px 170px 170px 170px !important;
    }
    #tableSmaller2{
        width: 1%;
            }
            #bigger2{
                width: 710px !important;
                margin-left: -9% !important;
            }
}
@media only screen and (min-device-width: 871px) and (max-device-width: 900px){

    #tContainer2 {
        flex-direction: column;
        
        
            }
            #firstCol2{
                padding: 1px !important;
                margin-left:320px !important;
               order: 1;
            }
            #secondCol2 {
                padding: 45px;
                margin-left: 285px !important;
                order: 2;
        
            }
            #thirdCol2 {
                padding:35px;
                margin-left: 285px !important;
                order: 3;
                
            }
            #fourthCol2 {
                padding: 45px !important;
                margin-left: 284px !important;
                order: 4;
        
            }
 
    #theDropDown{
        margin-right: -5% !important;  
    }
    #greenRowManipulationToFitMobileView {

        display: grid !important;
        grid-template-columns: 170px 170px 170px 170px !important;
    }
    #tableSmaller2{
        width: 1%;
            }
            #bigger2{
                width: 730px !important;
                margin-left: -9% !important;
            }
}
@media only screen and (min-device-width: 901px) and (max-device-width: 930px){
    #tContainer2 {
        flex-direction: column;
        
        
            }
            #firstCol2{
                padding: 1px !important;
                margin-left:330px !important;
               order: 1;
            }
            #secondCol2 {
                padding: 45px;
                margin-left: 295px !important;
                order: 2;
        
            }
            #thirdCol2 {
                padding:35px;
                margin-left: 295px !important;
                order: 3;
                
            }
            #fourthCol2 {
                padding: 45px !important;
                margin-left: 295px !important;
                order: 4;
        
            }
 
    #theDropDown{
        margin-right: -5% !important;  
    }
    #greenRowManipulationToFitMobileView {

        display: grid !important;
        grid-template-columns: 170px 170px 170px 170px !important;
    }
    #tableSmaller2{
        width: 1%;
            }
            #bigger2{
                width: 750px !important;
                margin-left: -8% !important;
            }
}
@media only screen and (min-device-width: 931px) and (max-device-width: 955px){
    #tContainer2 {
        flex-direction: column;
        
        
            }
            #firstCol2{
                padding: 1px !important;
                margin-left:335px !important;
               order: 1;
            }
            #secondCol2 {
                padding: 45px;
                margin-left: 295px !important;
                order: 2;
        
            }
            #thirdCol2 {
                padding:35px;
                margin-left: 295px !important;
                order: 3;
                
            }
            #fourthCol2 {
                padding: 45px !important;
                margin-left: 301px !important;
                order: 4;
        
            }
 
    #theDropDown{
        margin-right: -5% !important;  
    }
    #greenRowManipulationToFitMobileView {

        display: grid !important;
        grid-template-columns: 170px 170px 170px 170px !important;
    }
    #tableSmaller2{
        width: 1%;
            }
            #bigger2{
                width: 775px !important;
                margin-left: -7% !important;
            }
}
@media only screen and (min-device-width: 956px) and (max-device-width: 975px){
    #tContainer2 {
        display: grid !important;
        grid-template-columns: 260px 260px 260px !important;
        gap: 30px !important;
        
          }
          #theDropDown{
            margin-right: -5% !important;  
        }
    #tableSmaller2{
        width: 1%;
            }
            #bigger2{
                width: 805px !important;
                margin-left: -6% !important;
            }
}
@media only screen and (min-device-width: 976px) and (max-device-width: 990px){
    #tContainer2 {
        display: grid !important;
        grid-template-columns: 260px 260px 260px !important;
        gap: 30px !important;
        
          }
          #theDropDown{
            margin-right: -5% !important;  
        }
    #tableSmaller2{
        width: 1%;
            }
            #bigger2{
                width: 825px !important;
                margin-left: -6% !important;
            }
}

@media only screen and (min-device-width: 991px) and (max-device-width: 1024px){
    #tContainer2 {
        flex-direction: column;
        
        
            }
            #firstCol2{
                padding: 1px !important;
                margin-left:255px !important;
               order: 1;
            }
            #secondCol2 {
                padding: 45px;
                margin-left: 240px !important;
                order: 2;
        
            }
            #thirdCol2 {
                padding:35px;
                margin-left: 240px !important;
                order: 3;
                
            }
            #fourthCol2 {
                padding: 45px !important;
                margin-left: 220px !important;
                order: 4;
        
            }
 
    #theDropDown{
        margin-right: -5% !important;  
    }
    #greenRowManipulationToFitMobileView {

        display: grid !important;
        grid-template-columns: 155px 155px 155px 155px !important;
    }
    #tableSmaller2{
        width: 1%;
            }
            #bigger2{
                width: 600px !important;
                margin-left: -12% !important;
            }
}
@media only screen and (min-device-width: 1025px) and (max-device-width: 1070px){
    #tContainer2 {
        flex-direction: column;
        
        
            }
            #firstCol2{
                padding: 1px !important;
                margin-left:262px !important;
               order: 1;
            }
            #secondCol2 {
                padding: 45px;
                margin-left: 242px !important;
                order: 2;
        
            }
            #thirdCol2 {
                padding:35px;
                margin-left: 242px !important;
                order: 3;
                
            }
            #fourthCol2 {
                padding: 45px !important;
                margin-left: 227px !important;
                order: 4;
        
            }
 
    #theDropDown{
        margin-right: -5% !important;  
    }
    #greenRowManipulationToFitMobileView {

        display: grid !important;
        grid-template-columns: 155px 155px 155px 155px !important;
    }
    #tableSmaller2{
        width: 1%;
            }
            #bigger2{
                width: 615px !important;
                margin-left: -9% !important;
            }
}

@media only screen and (min-device-width: 1071px) and (max-device-width: 1100px){
    #tContainer2 {
        flex-direction: column;
        
        
            }
            #firstCol2{
                padding: 1px !important;
                margin-left:290px !important;
               order: 1;
            }
            #secondCol2 {
                padding: 45px;
                margin-left: 265px !important;
                order: 2;
        
            }
            #thirdCol2 {
                padding:35px;
                margin-left: 265px !important;
                order: 3;
                
            }
            #fourthCol2 {
                padding: 45px !important;
                margin-left: 256px !important;
                order: 4;
        
            }
 
    #theDropDown{
        margin-right: -5% !important;  
    }
    #greenRowManipulationToFitMobileView {

        display: grid !important;
        grid-template-columns: 155px 155px 155px 155px !important;
    }
    #tableSmaller2{
        width: 1%;
            }
            #bigger2{
                width: 665px !important;
                margin-left: -9% !important;
            }
}
@media only screen and (min-device-width: 1101px) and (max-device-width: 1140px){
    #tContainer2 {
        flex-direction: column;
        
        
            }
            #firstCol2{
                padding: 1px !important;
                margin-left:300px !important;
               order: 1;
            }
            #secondCol2 {
                padding: 45px;
                margin-left: 270px !important;
                order: 2;
        
            }
            #thirdCol2 {
                padding:35px;
                margin-left: 270px !important;
                order: 3;
                
            }
            #fourthCol2 {
                padding: 45px !important;
                margin-left: 265px !important;
                order: 4;
        
            }
 
    #theDropDown{
        margin-right: -5% !important;  
    }
    #greenRowManipulationToFitMobileView {

        display: grid !important;
        grid-template-columns: 155px 155px 155px 155px !important;
    }
    #tableSmaller2{
        width: 1%;
            }
            #bigger2{
                width: 700px !important;
                margin-left: -9% !important;
            }
}
@media only screen and (min-device-width: 1141px) and (max-device-width: 1180px){
    #tContainer2 {
        flex-direction: column;
        
        
            }
            #firstCol2{
                padding: 1px !important;
                margin-left:315px !important;
               order: 1;
            }
            #secondCol2 {
                padding: 45px;
                margin-left: 283px !important;
                order: 2;
        
            }
            #thirdCol2 {
                padding:35px;
                margin-left: 283px !important;
                order: 3;
                
            }
            #fourthCol2 {
                padding: 45px !important;
                margin-left: 279px !important;
                order: 4;
        
            }
 
    #theDropDown{
        margin-right: -5% !important;  
    }
    #greenRowManipulationToFitMobileView {

        display: grid !important;
        grid-template-columns: 165px 165px 165px 165px !important;
    }
    #tableSmaller2{
        width: 1%;
            }
            #bigger2{
                width: 720px !important;
                margin-left: -6% !important;
            }
}

@media only screen and (min-device-width: 1181px) and (max-device-width: 1210px){
    #tContainer2 {
        flex-direction: column;
        
        
            }
            #firstCol2{
                padding: 1px !important;
                margin-left:335px !important;
               order: 1;
            }
            #secondCol2 {
                padding: 45px;
                margin-left: 297px !important;
                order: 2;
        
            }
            #thirdCol2 {
                padding:35px;
                margin-left: 297px !important;
                order: 3;
                
            }
            #fourthCol2 {
                padding: 45px !important;
                margin-left: 300px !important;
                order: 4;
        
            }
 
    #theDropDown{
        margin-right: -5% !important;  
    }
    #greenRowManipulationToFitMobileView {

        display: grid !important;
        grid-template-columns: 165px 165px 165px 165px !important;
    }
    #tableSmaller2{
        width: 1%;
            }
            #bigger2{
                width: 765px !important;
                margin-left: -6% !important;
            }
}

@media only screen and (min-device-width: 1211px) and (max-device-width: 1240px){
    #tContainer2 {
        display: grid !important;
        grid-template-columns: 250px 250px 250px !important;
        gap: 30px !important;
        
          }
    #tableSmaller2{
        width: 1%;
            }
            #bigger2{
                width: 790px !important;
                margin-left: -6% !important;
            }
}
@media only screen and (min-device-width: 1241px) and (max-device-width: 1270px){

    #secondCol2 {
        padding-left: 50px;
    }
    #thirdCol2 {
        padding-left: 150px;
    }
    #fourthCol2{
        padding-left: 100px;
    }

    #theDropDown{
        margin-right: -1% !important;  
    }
    #greenRowManipulationToFitMobileView {

        display: grid !important;
        grid-template-columns: 165px 165px 165px 165px !important;
    }
    #tableSmaller2{
        width: 1%;
            }
            #bigger2{
                width: 820px !important;
                margin-left: -6% !important;
            }
}
@media only screen and (min-device-width: 1271px) and (max-device-width: 1300px){
    #secondCol2 {
        padding-left: 70px;
    }
    #thirdCol2 {
        padding-left: 150px;
    }
    #fourthCol2{
        padding-left: 100px;
    }

    #theDropDown{
        margin-right: -1% !important;  
    }
    #greenRowManipulationToFitMobileView {

        display: grid !important;
        grid-template-columns: 165px 165px 165px 165px !important;
    }
    #tableSmaller2{
        width: 1%;
            }
            #bigger2{
                width: 860px !important;
                margin-left: -6% !important;
            }
}
@media only screen and (min-device-width: 1301px) and (max-device-width: 1330px){
    #secondCol2 {
        padding-left: 70px;
    }
    #thirdCol2 {
        padding-left: 150px;
    }
    #fourthCol2{
        padding-left: 100px;
    }

    #theDropDown{
        margin-right: -1% !important;  
    }
    #greenRowManipulationToFitMobileView {

        display: grid !important;
        grid-template-columns: 165px 165px 165px 165px !important;
    }
    #tableSmaller2{
        width: 1%;
            }
            #bigger2{
                width: 895px !important;
                margin-left: -6% !important;
            }
}