.App {
    text-align: center;
    object-fit: contain;
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
      float: right;
      right: 0px;
      object-fit: contain;
     /* padding-top: -0%;*/
     /* padding-bottom: 100%;*/
     background-color: white;
      min-height: 25vh;
     display: flex;
      flex-direction: column;
     align-items: center;
        justify-content: center;
       font-size: calc(10px + 2vmin);
       color: white;
         /*
       right: 40px;
     display: flex;
      background-color: #282828;
      */
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  /* chatbot button css */
  .chatbot-button {
    width: 65px;
    height: 65px;
    border-radius: 50px;
    background-color: white;
    border: none;
    position: fixed;
    bottom: 45px;
    z-index: 9999;
    right: 40px;
  }
  .chatbot-icon {
    width: 50px;
    height: 50px;
  }

  .Chatbot {
      right: 50px;
     position: fixed;
     bottom: 100px;
     /* float: right;*/
  }