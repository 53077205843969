@media screen and (max-width: 600px) {
#formView {
width: 500px;
    }



}

@media only screen and (min-device-width: 300px) and (max-device-width:320px) {
    
    #formView {
        width: 450px;
        margin-left: -16%;
            }
        
}
@media only screen and (min-device-width: 321px) and (max-device-width:360px) {
    
    #formView {
        width: 450px;
        margin-left: -12%;
            }
        
}