*,
*::before,
*::after {
  box-sizing: border-box;
  
}
select {
   /* appearance: none;*/
    background-color: transparent;
    border-color:gainsboro;
    /*border: 5px;*/
   /* border-style:solid;*/
    border-radius: 4px;
    padding: 0 1em 0 0;
    margin: 0;
    width: 100%;
    font-family: inherit;
    font-size: inherit;
    cursor: inherit;
    line-height: inherit;
    height: 40px;
    color:dimgrey;
  }

@media screen and (max-width: 1330px) {
#resizeform{
width:fit-content;
}

}