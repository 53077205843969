.token-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .token-buttons {
    margin: 5px;
    border-radius: 5px;
    padding: 8px;
    border-color: green;
    background: transparent;
    text-align: center;
    font-size: 0.8rem;
    
  }